import React  from "react";
import { useState, useEffect } from "react";


const safeJSONParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.error("Failed to parse JSON string:", str, e);
    return null;
  }
};

const formatDateForInput = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    const date = new Date(timestamp.seconds * 1000);

    // Get the timezone offset in minutes and convert it to milliseconds
    const timeZoneOffset = date.getTimezoneOffset() * 60000;

    // Adjust the date by the timezone offset
    const localDate = new Date(date.getTime() - timeZoneOffset);

    return localDate.toISOString().slice(0, 16); // Returns datetime in YYYY-MM-DDTHH:MM format
  }
  return "";
};

const convertToFirestoreTimestamp = (isoString) => {
  const date = new Date(isoString);
  if (!isNaN(date.getTime())) {
    return {
      seconds: Math.floor(date.getTime() / 1000),
      nanoseconds: (date.getTime() % 1000) * 1000000
    };
  }
  return null;
};

const formatDate = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  }
  return "";
};

const GoalPopup = ({ show, onClose, fileDetails, onSave }) => {
  const [localFileDetails, setLocalFileDetails] = useState(fileDetails || {});

  useEffect(() => {
    setLocalFileDetails(fileDetails || {});
  }, [fileDetails]);

  const handleInputChange = (field, value) => {
    setLocalFileDetails({ ...localFileDetails, [field]: value });
  };
  

  const handleDateChange = (field, isoString) => {
    const formattedDate = convertToFirestoreTimestamp(isoString);
    handleInputChange(field, formattedDate);
  };

  const handleSave = () => {
    if (onSave) {
      onSave(localFileDetails, fileDetails); // Pass updated and original details to onSave
    }
    onClose();
  };

  if (!show || !localFileDetails) {
    return null;
  }


  return (
    <div
    style={{
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgb(25, 255, 255, 0.8)",
      padding: "20px",
      zIndex: 1000,
      overflowY: "auto",
      maxHeight: "50vh",
      width: "40vw",
      color: "black",
    }}
  >
      <button onClick={handleSave}>Save</button>

      <button onClick={onClose}>Close</button>
      <h2>{fileDetails.fileName}</h2>
      <p>File Name:</p>
      <input
        type="text"
        value={localFileDetails.fileName || ''}
        onChange={(e) => handleInputChange('fileName', e.target.value)}
        />

      {/* Render other file details here */}
      <p>Description: </p>
      <input
        type="text"
        value={localFileDetails.description || ''}
        onChange={(e) => handleInputChange("description", e.target.value)}

      />

      {/* Add more details as needed */}
      {/* Example of formatting Firestore Timestamp fields */}
      <p>Start Date: </p>
    <input
      type="datetime-local"
      value={formatDateForInput(localFileDetails.startDate)}
      onChange={(e) => handleDateChange("startDate", e.target.value)}
      />

    <p>End Date: </p>
    <input
      type="datetime-local"
      value={formatDateForInput(localFileDetails.endDate)}
      onChange={(e) => handleDateChange("endDate", e.target.value)}
    />

    <p>Reminder Alert: </p>
    <input
      type="datetime-local"
      value={formatDateForInput(localFileDetails.reminderAlert)}
      onChange={(e) => handleDateChange("reminderAlert", e.target.value)}
    />
      {/* Render other details */}
      <p>Category: </p>
      <input
        type="text"
        value={localFileDetails.category || ''}
        onChange={(e) => handleInputChange("category", e.target.value)}
          />

      <p>Priority:</p>
      <input
        type="text"
        value={localFileDetails.priority || ''}
        onChange={(e) => handleInputChange("priority", e.target.value)}
      />
      <p>Reminder: </p>

      <input
        type="text"
        value={localFileDetails.reminder || ''}
        onChange={(e) => handleInputChange("reminder", e.target.value)}
      />

      <p>Favorite: </p>
      <input
        type="text"
        value={localFileDetails.favorite || ''}
        onChange={(e) => handleInputChange("favorite", e.target.value)}
      />

      <p>Link URL: </p>
      <input
        type="text"
        value={localFileDetails.linkURL || ''}
        onChange={(e) => handleInputChange("linkURL", e.target.value)}
      />

      <p>Link File: </p>

      <input
        type="text"
        value={localFileDetails.linkFile || ''}
        onChange={(e) => handleInputChange("linkFile", e.target.value)}
      />

      <p>Repeat:</p>
      <input
        type="text"
        value={localFileDetails.repeat || ''}
        onChange={(e) => handleInputChange("repeat", e.target.value)}
      />
      <p>Assign To:</p>
      <input
        type="text"
        value={localFileDetails.assignTo || ''}
        onChange={(e) => handleInputChange("assignTo", e.target.value)}
      />

      <p>Status:</p>
      <input
        type="text"
        value={localFileDetails.status || ''}
        onChange={(e) => handleInputChange("status", e.target.value)}
      />

      <p>In App Category: </p>
      <input
        type="text"
        value={localFileDetails.InAppCategory || ''}
        onChange={(e) => handleInputChange("InAppCategory", e.target.value)}
      />

      <p>Doc Link:</p>
      <input
        type="text"
        value={localFileDetails.docLink || ''}
        onChange={(e) => handleInputChange("docLink", e.target.value)}
      />
    </div>
  );
};

export default GoalPopup;

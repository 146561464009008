// In Routines.js
export const playRoutine = (setIsPlaying, setCurrentStepIndex, steps, startTimerFunction) => {
    setIsPlaying(true);
    const firstStepIndex = 0;
    setCurrentStepIndex(firstStepIndex);
    startTimerFunction(steps[firstStepIndex], firstStepIndex);
};

export const startTimer = (step, stepIndex, setTimer, intervalId, setIntervalId, setCurrentStepIndex, steps, finishRoutine) => {
    const totalSeconds =
    step.duration.hours * 3600 +
    step.duration.minutes * 6 +
    step.duration.seconds;
  setTimer(totalSeconds);
  clearInterval(intervalId); // Clear any existing timer

  const id = setInterval(() => {
    setTimer((prevTimer) => {
      if (prevTimer <= 1) {
        clearInterval(id); // Stop the timer

        const nextIndex = stepIndex + 1;
        if (nextIndex < steps.length) {
          setCurrentStepIndex(nextIndex);
          const nextStep = steps[nextIndex];
          // Important: call startTimer with next step and its index
          startTimer(nextStep, nextIndex);

          return (
            nextStep.duration.hours * 3600 +
            nextStep.duration.minutes * 6 +
            nextStep.duration.seconds
          );
        } else {
          finishRoutine();
        }
        return 0;
      }
      return prevTimer - 1; // Decrement timer
    });
  }, 1000);

  setIntervalId(id);};

import { property } from "lodash";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import "../CSS/Money.css";

const Money = () => {
    const navigate = useNavigate();

    const [filterName, setFilterName] = useState("");
const [sortType, setSortType] = useState("date"); // default sorting by date
const [sortOrder, setSortOrder] = useState("asc"); // default sorting order ascending

    const [cash, setCash] = useState(0);
    const [bank, setBank] = useState(0);
    const [debt, setDebt] = useState(0);
    const [investment, setInvestment] = useState(0);
    const [property, setProperty] = useState(0);
    const [showAddCashPop, setShowAddCashPop] = useState(false);
    const [showAddBankPop, setShowAddBankPop] = useState(false);
    const [showAddDebtPop, setShowAddDebtPop] = useState(false);
    const [showAddInvestmentPop, setShowAddInvestmentPop] = useState(false);
    const [showAddPropertyPop, setShowAddPropertyPop] = useState(false);
    const [isIncome, setIsIncome] = useState(true);

    const [bankName, setBankName] = useState("");
    const [debtName, setDebtName] = useState("");
    const [investmentName, setInvestmentName] = useState("");
    const [propertyName, setPropertyName] = useState("");


    const [expenses, setExpenses] = useState([]);
    const [showHistoryPop, setShowHistoryPop] = useState(false); // State to show/hide history popup

    const [cashName, setCashName] = useState(""); // Added state for cashName
    // ... other state variables

    
    const fetchData = async () => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(collection(db, "expenses"));
            let totalCash = 0;
            let totalBank = 0;
            let totalDebt = 0;
            let totalInvestment = 0;
            let totalProperty = 0;

            // ... other totals

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                switch (data.type) {
                    case 'cash':
                        totalCash += data.amount;
                        break;
                    case 'bank':
                        totalBank += data.amount;
                        // ... handle other types
                        break;
                    case 'debt':
                        totalDebt += data.amount;
                        break;
                    case 'investment':
                        totalInvestment += data.amount;
                        break;
                    case 'property':
                        totalProperty += data.amount;
                        break;
                    default:
                        break;

                }
            });

            setCash(totalCash);
            setBank(totalBank);
            setDebt(totalDebt);
            setInvestment(totalInvestment);
            setProperty(totalProperty);

            // ... set other states
        };

        fetchData();


    };


    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(collection(db, "expenses"));
            let expensesData = [];

            let totalCash = 0;
            let totalBank = 0;
            let totalDebt = 0;
            let totalInvestment = 0;
            let totalProperty = 0;

            // ... other totals

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                expensesData.push(data);

                switch (data.type) {
                    case 'cash':
                        totalCash += data.amount;
                        break;
                    case 'bank':
                        totalBank += data.amount;
                        // ... handle other types
                        break;
                    case 'debt':
                        totalDebt += data.amount;
                        break;
                    case 'investment':
                        totalInvestment += data.amount;
                        break;
                    case 'property':
                        totalProperty += data.amount;
                        break;
                    default:
                        break;

                }
            });

            setCash(totalCash);
            setBank(totalBank);
            setDebt(totalDebt);
            setInvestment(totalInvestment);
            setProperty(totalProperty);
            setExpenses(expensesData); // Update expenses history

            // ... set other states
        };

        fetchData();
    }, []);

    const handleShowHistory = () => {
        setShowHistoryPop(true);
    };

    const HistoryPop = () => {
        const handleSort = (expensesData) => {
            // Implement sorting logic based on sortType and sortOrder
            // Return the sorted array
        };
        const filteredAndSortedExpenses = handleSort(
            expenses.filter(expense => expense.name.includes(filterName))
        );
        return (
            <div className="overlay" onClick={() => setShowHistoryPop(false)}>
                <div className="popup" onClick={(e) => e.stopPropagation()}>
                    <button onClick={() => setShowHistoryPop(false)} className="close-popup">Close</button>
                    <h2>History</h2>
                    <input
                    type="text"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                    placeholder="Filter by Name"
                />
                                <select value={sortType} onChange={(e) => setSortType(e.target.value)}>
                    <option value="date">Date</option>
                    <option value="amount">Amount</option>
                    <option value="type">Type</option>
                    <option value="name">Name</option>
                </select>
                <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                </select>
                <div>
                {expenses && expenses.map((expense, index) => (
                        <button key={index} className="history-item">
                            <p>Type: {expense.type}</p>
                            <p>Name: {expense.name}</p>
                            <p>Amount: {expense.amount}</p>
                            <p>Date: {expense.date.toDate().toDateString()}</p> {/* Assuming date is a Firestore Timestamp */}
                        </button>
                    ))}
                </div>
                </div>
            </div>
        );
    };
    
    

    const addExpenseToDB = async (type, amount, name) => {
        try {
            await addDoc(collection(db, "expenses"), {
                type,
                amount: isIncome ? amount : -amount,
                name,
                date: new Date(),
            });
            alert("Expense added successfully.");
        } catch (error) {
            console.error("Error adding document: ", error);
            alert("Error adding expense.");
        }
    };

    const handleNavigateToDesktop = () => {
        navigate("/desktop");
    };

    const handleAddCashSubmit = () => {
        // Add to DB and update state
        addExpenseToDB("cash", parseFloat(cash), cashName);
        setShowAddCashPop(false);
    };
    const handleAddBankSubmit = () => {
        addExpenseToDB("bank", parseFloat(bank), bankName);
        setShowAddBankPop(false);
    };
    const handleAddDebtSubmit = () => {
        addExpenseToDB("debt", parseFloat(debt), debtName);
        setShowAddDebtPop(false);
    };
    const handleAddInvestmentSubmit = () => {
        addExpenseToDB("investment", parseFloat(investment), investmentName);
        setShowAddInvestmentPop(false);
    };
    const handleAddPropertySubmit = () => {
        addExpenseToDB("property", parseFloat(property), propertyName);
        setShowAddPropertyPop(false);
    };


    const addNewCash = () => {
        if (!cashName) {
            alert("Please enter a cash name.");
            return;
        }
        setCash(cash + 1);
        setCashName(""); // Reset the cash name
    };

    const addNewBank = () => {
        if (!bankName) {
            alert("Please enter a bank name.");
            return;
        }
        setBank(bank + 1);
        setBankName(""); // Reset the bank name
    };

    const addNewDebt = () => {
        if (!debtName) {
            alert("Please enter a debt name.");
            return;
        }
        setDebt(debt + 1);
        setDebtName(""); // Reset the debt name
    };

    const addNewInvestment = () => {
        if (!investmentName) {
            alert("Please enter an investment name.");
            return;
        }
        setInvestment(investment + 1);
        setInvestmentName(""); // Reset the investment name
    };

    const addNewProperty = () => {
        if (!propertyName) {


            alert("Please enter a property name.");


            return;
        }
        setProperty(property + 1);
        setPropertyName(""); // Reset the property name
    };


    // ... similar functions for bank, debt, investment, and property

    const sum = cash + bank + debt + property + investment; // ... add bank, debt, investment, and property to this sum

    const AddCashPop = () => {
        const [localAmount, setLocalAmount] = useState(0);
        const [localName, setLocalName] = useState("");
        const [localIsIncome, setLocalIsIncome] = useState(true);

        const handleLocalSubmit = async () => {
            const amount = parseFloat(localAmount);
            if (isNaN(amount)) {
                alert("Please enter a valid amount.");
                return;
            }

            try {
                await addDoc(collection(db, "expenses"), {
                    type: 'cash',
                    amount: localIsIncome ? amount : -amount,
                    name: localName,
                    date: new Date(),
                });
                fetchData();  // Fetch data again to update parent state
                setLocalAmount(0);
                setLocalName("");
                setShowAddCashPop(false);
                alert("Expense added successfully.");
            } catch (error) {
                console.error("Error adding document: ", error);
                alert("Error adding expense.");
            }
        };

        return (
            <div className="overlay" onClick={() => setShowAddCashPop(false)}>
                <div className="popup" onClick={(e) => e.stopPropagation()}>

                    <button onClick={() => setShowAddCashPop(false)} className="close-popup">
                        Close
                    </button>
                    <h2>Add New Cash</h2>
                    <div>
                        <input

                            type="number"
                            value={localAmount}
                            onChange={(e) => setLocalAmount(e.target.value)}
                            placeholder="Enter Amount"
                            className="file-input"
                        />
                        <input

                            type="text"
                            value={localName}
                            onChange={(e) => setLocalName(e.target.value)}
                            placeholder="Enter Name"
                            className="file-input"
                        />
                        <label>Income</label>

                        <input
                            type="checkbox"
                            checked={localIsIncome}
                            onChange={(e) => setLocalIsIncome(e.target.checked)}
                            className="file-input"
                        />
                        <button onClick={handleLocalSubmit} className="add-file-button">
                            Add Cash
                        </button>



                    </div>

                </div>
            </div>
        );
    };

    const AddBankPop = () => {
        const [localAmount, setLocalAmount] = useState(0);
        const [localName, setLocalName] = useState("");
        const [localIsIncome, setLocalIsIncome] = useState(true);

        const handleLocalSubmit = async () => {
            const amount = parseFloat(localAmount);
            if (isNaN(amount)) {
                alert("Please enter a valid amount.");
                return;
            }

            try {
                await addDoc(collection(db, "expenses"), {
                    type: 'bank',
                    amount: localIsIncome ? amount : -amount,
                    name: localName,
                    date: new Date(),
                });
                fetchData();  // Fetch data again to update parent state
                setLocalAmount(0);
                setLocalName("");
                setShowAddBankPop(false);
                alert("Expense added successfully.");
            } catch (error) {
                console.error("Error adding document: ", error);
                alert("Error adding expense.");
            }
        };
        return (
            
            <div className="overlay" onClick={() => setShowAddBankPop(false)}>
        
                <div className="popup" onClick={(e) => e.stopPropagation()}>
                    <button onClick={() => setShowAddBankPop(false)} className="close-popup">
                        Close
                    </button>
                    <h2>Add New Bank</h2>
                    <div>
                        <input
                            type="number"
                            value={localAmount}
                            onChange={(e) => setLocalAmount(e.target.value)}
                            placeholder="Enter Amount"
                            className="file-input"
                        />
                        <input
                            type="text"
                            value={localName}
                            onChange={(e) => setLocalName(e.target.value)}
                            placeholder="Enter Name"
                            className="file-input"
                        />
                        <label>Income</label>

                        <input
                            type="checkbox"
                            checked={localIsIncome}
                            onChange={(e) => setLocalIsIncome(e.target.checked)}
                            className="file-input"
                        />
                        <button onClick={handleLocalSubmit} className="add-file-button">
                            Add Bank
                        </button>
                    </div>
                </div>
            </div>
        );




    };

    const AddDebtPop = () => {
        const [localAmount, setLocalAmount] = useState(0);
        const [localName, setLocalName] = useState("");
        const [localIsIncome, setLocalIsIncome] = useState(true);

        const handleLocalSubmit = async () => {
            const amount = parseFloat(localAmount);
            if (isNaN(amount)) {
                alert("Please enter a valid amount.");
                return;
            }

            try {
                await addDoc(collection(db, "expenses"), {
                    type: 'debt',
                    amount: localIsIncome ? amount : -amount,
                    name: localName,
                    date: new Date(),
                });
                fetchData();  // Fetch data again to update parent state
                setLocalAmount(0);
                setLocalName("");
                setShowAddDebtPop(false);
                alert("Expense added successfully.");
            } catch (error) {
                console.error("Error adding document: ", error);
                alert("Error adding expense.");
            }
        };

        return (
            <div className="overlay" onClick={() => setShowAddDebtPop(false)}>
                <div className="popup" onClick={(e) => e.stopPropagation()}>
                    <button onClick={() => setShowAddDebtPop(false)} className="close-popup">
                        Close
                    </button>
                    <h2>Add New Debt</h2>
                    <div>
                        <input
                            type="number"
                            value={localAmount}
                            onChange={(e) => setLocalAmount(e.target.value)}
                            placeholder="Enter Amount"
                            className="file-input"
                        />
                        <input
                            type="text"
                            value={localName}
                            onChange={(e) => setLocalName(e.target.value)}
                            placeholder="Enter Name"
                            className="file-input"
                        />
                        <label>Income</label>

                        <input
                            type="checkbox"
                            checked={localIsIncome}
                            onChange={(e) => setLocalIsIncome(e.target.checked)}
                            className="file-input"
                        />
                        <button onClick={handleLocalSubmit} className="add-file-button">
                            Add Debt
                        </button>
                    </div>
                </div>
            </div>
        );

    };

    const AddInvestmentPop = () => {
        const [localAmount, setLocalAmount] = useState(0);
        const [localName, setLocalName] = useState("");
        const [localIsIncome, setLocalIsIncome] = useState(true);

        const handleLocalSubmit = async () => {
            const amount = parseFloat(localAmount);
            if (isNaN(amount)) {
                alert("Please enter a valid amount.");
                return;
            }

            try {
                await addDoc(collection(db, "expenses"), {
                    type: 'investment',
                    amount: localIsIncome ? amount : -amount,
                    name: localName,
                    date: new Date(),
                });
                fetchData();  // Fetch data again to update parent state
                setLocalAmount(0);
                setLocalName("");
                setShowAddInvestmentPop(false);
                alert("Expense added successfully.");
            } catch (error) {
                console.error("Error adding document: ", error);
                alert("Error adding expense.");
            }
        };
        return (
            <div className="overlay" onClick={() => setShowAddInvestmentPop(false)}>
                <div className="popup" onClick={(e) => e.stopPropagation()}>
                    <button onClick={() => setShowAddInvestmentPop(false)} className="close-popup">
                        Close
                    </button>
                    <h2>Add New Investment</h2>
                    <div>
                        <input
                            type="number"
                            value={localAmount}
                            onChange={(e) => setLocalAmount(e.target.value)}
                            placeholder="Enter Amount"
                            className="file-input"
                        />
                        <input
                            type="text"
                            value={localName}
                            onChange={(e) => setLocalName(e.target.value)}
                            placeholder="Enter Name"
                            className="file-input"
                        />
                        <label>Income</label>

                        <input
                            type="checkbox"
                            checked={localIsIncome}
                            onChange={(e) => setLocalIsIncome(e.target.checked)}
                            className="file-input"
                        />
                        <button onClick={handleLocalSubmit} className="add-file-button">
                            Add Investment
                        </button>
                    </div>
                </div>
            </div>
        );

    };

    const AddPropertyPop = () => {
        const [localAmount, setLocalAmount] = useState(0);
        const [localName, setLocalName] = useState("");
        const [localIsIncome, setLocalIsIncome] = useState(true);

        const handleLocalSubmit = async () => {

            const amount = parseFloat(localAmount);
            if (isNaN(amount)) {
                alert("Please enter a valid amount.");
                return;
            }

            try {
                await addDoc(collection(db, "expenses"), {
                    type: 'property',
                    amount: localIsIncome ? amount : -amount,
                    name: localName,
                    date: new Date(),
                });
                fetchData();  // Fetch data again to update parent state
                setLocalAmount(0);
                setLocalName("");
                setShowAddPropertyPop(false);
                alert("Expense added successfully.");
            }
            catch (error) {
                console.error("Error adding document: ", error);
                alert("Error adding expense.");
            }
        };
        return (
            <div className="overlay" onClick={() => setShowAddPropertyPop(false)}>
                <div className="popup" onClick={(e) => e.stopPropagation()}>
                    <button onClick={() => setShowAddPropertyPop(false)} className="close-popup">
                        Close
                    </button>
                    <h2>Add New Property</h2>
                    <div>
                        <input
                            type="number"
                            value={localAmount}
                            onChange={(e) => setLocalAmount(e.target.value)}
                            placeholder="Enter Amount"
                            className="file-input"
                        />
                        <input
                            type="text"
                            value={localName}
                            onChange={(e) => setLocalName(e.target.value)}
                            placeholder="Enter Name"
                            className="file-input"
                        />
                        <label>Income</label>

                        <input
                            type="checkbox"
                            checked={localIsIncome}
                            onChange={(e) => setLocalIsIncome(e.target.checked)}
                            className="file-input"
                        />
                        <button onClick={handleLocalSubmit} className="add-file-button">
                            Add Property
                        </button>
                    </div>
                </div>
            </div>
        );

    };

    return (
        <div>
            {/* ... other components */}
            <button onClick={handleShowHistory}>Show History</button>
                            {showHistoryPop && <HistoryPop />}

            <h1>Money</h1>
            <h2>Cash</h2>
            <h3>{cash}</h3>
            <h2>Bank</h2>
            <h3>{bank}</h3>
            <h2>Debt</h2>
            <h3>{debt}</h3>
            <h2>Investment</h2>
            <h3>{investment}</h3>
            <h2>Property</h2>
            <h3>{property}</h3>
            <h1>Total: {sum}</h1>
            <button onClick={() => setShowAddCashPop(true)}>Add Cash</button>
            {showAddCashPop && <AddCashPop />}
            <button onClick={() => setShowAddBankPop(true)}>Add Bank</button>
            {showAddDebtPop && <AddDebtPop />}
            <button onClick={() => setShowAddDebtPop(true)}>Add Debt</button>
            {showAddBankPop && <AddBankPop />}
            <button onClick={() => setShowAddInvestmentPop(true)}>Add Investment</button>
            {showAddInvestmentPop && <AddInvestmentPop />}
            <button onClick={() => setShowAddPropertyPop(true)}>Add Property</button>
            {showAddPropertyPop && <AddPropertyPop />}

            {/* ... other components */}
        </div>
    );
};

export default Money;

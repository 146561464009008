import React, { useState, useEffect } from "react";
import "../CSS/TasksStyles.css"; // Your custom calendar styles
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { addNewFile } from '../Components/FileDuplicateHelpers'; // Adjust the path as needed

function Tasks() {

    const [showNotStarted, setShowNotStarted] = useState(true);
const [showInProgress, setShowInProgress] = useState(true);
const [showCompleted, setShowCompleted] = useState(false);
const [currentDeskPath, setCurrentDeskPath] = useState("/desktop"); // Add state for current path
const [pathHistory, setPathHistory] = useState(['/desktop']); // Initialize with the root path


  const [dropdownValue, setDropdownValue] = useState("today"); // Set default dropdown value to 'today'

  const [tasks, setTasks] = useState([]);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const db = getFirestore();
  const [DeskOrTasksView, setDeskOrTasksView] = useState("TasksView");



  const handleDuplicate = async (task) => {
    try {
      await addNewFile(task); // Ensure that 'task' is the correct file object to duplicate
    } catch (error) {
      console.error("Error duplicating file:", error);
    }
  };


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
        if (currentUser) {
            setUser(currentUser);
            // Fetch tasks for today initially as we start in TasksView
            fetchDesks(currentUser.uid, 'today');
        } else {
            alert('No user logged in.');
        }
    });

    return () => unsubscribe();
}, []);



  const fetchDesks = async (userId, dropdownValue) => {
    let q;
    if (DeskOrTasksView === 'DesktopView') {
      q = query(collection(db, "Desktop"), where("userId", "==", userId), where("currentPath", "==", currentDeskPath));
    } else {
      q = query(collection(db, "Desktop"), where("userId", "==", userId));
      // existing code for 'TasksView'
    }

    const querySnapshot = await getDocs(q);
    let tasksFromQuery = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const now = new Date();
    // Only apply filtering if the dropdownValue is not 'all'
    let filteredTasks = tasksFromQuery;
    if (DeskOrTasksView === 'TasksView' && dropdownValue !== "all") {
        // Define start and end dates
      let startDate = new Date(now);
      let endDate = new Date(now);
  
      switch (dropdownValue) {
        case "today":
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(23, 59, 59, 999);
          break;
        case "tomorrow":
          startDate.setDate(startDate.getDate() + 1);
          startDate.setHours(0, 0, 0, 0);
          endDate.setDate(endDate.getDate() + 1);
          endDate.setHours(23, 59, 59, 999);
          break;
        case "yesterday":
          startDate.setDate(startDate.getDate() - 1);
          startDate.setHours(0, 0, 0, 0);
          endDate.setDate(endDate.getDate() - 1);
          endDate.setHours(23, 59, 59, 999);
          break;
    case "important":
      filteredTasks = tasksFromQuery.filter(task => task.priority === "Important");
      break;
      case "forgotten":
        filteredTasks = tasksFromQuery.filter(task => {
          const taskEndDate = task.endDate ? new Date(task.endDate.seconds * 1000) : null;
          return task.status !== "Completed" && taskEndDate && taskEndDate < now;
        });
            break;
          default:
        // Additional cases as needed
        filteredTasks = tasksFromQuery; // Default assignment if none of the cases match

      }
  
      if (dropdownValue !== 'important' && dropdownValue !== 'forgotten') {

        filteredTasks = filteredTasks.filter(task => {
            const taskDate = task.startDate ? new Date(task.startDate.seconds * 1000) : null;
        let isDateValid = taskDate && taskDate >= startDate && taskDate <= endDate;
  
        const isStatusValid = (showNotStarted && task.status === "Not Started") ||
                              (showInProgress && task.status === "In Progress") ||
                              (showCompleted && task.status === "Completed");
  
        return isDateValid && isStatusValid;
      });
    }
}
    setTasks(filteredTasks);
  };
  
  
  useEffect(() => {
    if (user) {
      if (DeskOrTasksView === 'DesktopView') {
        fetchTasksForDeskView(user.uid, currentDeskPath);
      } else {
        fetchDesks(user.uid, dropdownValue);
      }
    }
  }, [showNotStarted, showInProgress, showCompleted, dropdownValue, DeskOrTasksView, currentDeskPath, user]);
  


  const toggleView = () => {
    setDeskOrTasksView(prevView => {
        const newView = prevView === 'DesktopView' ? 'TasksView' : 'DesktopView';

        if (newView === 'DesktopView') {
            setDropdownValue('all');
            fetchDesks(user.uid, 'all');
        } else {
            setDropdownValue('today');
            fetchDesks(user.uid, 'today');
        }

        return newView;
    });
};
const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };
  

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    switch (name) {
      case "Not Started":
        setShowNotStarted(checked);
        break;
      case "In Progress":
        setShowInProgress(checked);
        break;
      case "Completed":
        setShowCompleted(checked);
        break;
      default:
        break;
    }
  };
  
  
  const handleGoClick = (taskPath) => {
    navigate(taskPath);
  };

  const handleTaskClick = async (taskPath) => {
    if (DeskOrTasksView === "TasksView") {
      navigate(taskPath);
    } else {
      setCurrentDeskPath(taskPath); // Update current path
      setPathHistory(prevHistory => [...prevHistory, taskPath]); // Add new path to history
      await fetchTasksForDeskView(user.uid, taskPath);
    }
  };
  

  const handleUpOneLevel = () => {
    if (pathHistory.length > 1) {
      const newPathHistory = pathHistory.slice(0, -1);
      setPathHistory(newPathHistory);
      const newCurrentDeskPath = newPathHistory[newPathHistory.length - 1];
      setCurrentDeskPath(newCurrentDeskPath);
      fetchTasksForDeskView(user.uid, newCurrentDeskPath);
    }
  };

  
  const getStatusStyle = (status) => {
    switch (status) {
      case 'Not Started':
        return { backgroundColor: 'red', borderRadius: '50%', width: '15px', height: '15px' };
      case 'In Progress':
        return { backgroundColor: 'green', borderRadius: '50%', width: '15px', height: '15px' };
      case 'Completed':
        return { backgroundColor: 'blue', borderRadius: '50%', width: '15px', height: '15px' };
      default:
        return {};
    }
  };
  const fetchTasksForDeskView = async (userId, path) => {
    // Fetch tasks that have the same currentPath as the clicked item's path
    const q = query(
      collection(db, "Desktop"),
      where("userId", "==", userId),
      where("currentPath", "==", path)
    );
  
    const querySnapshot = await getDocs(q);
    const tasksFromQuery = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  
    // Filter tasks based on status and dropdown value
    const filteredTasks = tasksFromQuery.filter(task => {
      // Include tasks without a status regardless of filters
      if (task.status === undefined) {
        return true;
      }
  
      // Filter by status
      const isStatusValid = (showNotStarted && task.status === "Not Started") ||
                            (showInProgress && task.status === "In Progress") ||
                            (showCompleted && task.status === "Completed");
  
      // Filter by dropdown value
      let isDateValid = true; // Default to true for all tasks
      if (dropdownValue !== 'all') {
        const taskDate = task.startDate ? new Date(task.startDate.seconds * 1000) : null;
        const now = new Date();
        let startDate = new Date(now);
        let endDate = new Date(now);
  
        switch (dropdownValue) {
          case "today":
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            break;
          case "tomorrow":
            startDate.setDate(startDate.getDate() + 1);
            startDate.setHours(0, 0, 0, 0);
            endDate.setDate(endDate.getDate() + 1);
            endDate.setHours(23, 59, 59, 999);
            break;
          case "yesterday":
            startDate.setDate(startDate.getDate() - 1);
            startDate.setHours(0, 0, 0, 0);
            endDate.setDate(endDate.getDate() - 1);
            endDate.setHours(23, 59, 59, 999);
            break;
            
          case "important":
            filteredTasks = tasksFromQuery.filter(task => task.priority === "Important");
            break;
          case "forgotten":
            filteredTasks = tasksFromQuery.filter(task => {
              const taskEndDate = task.endDate ? new Date(task.endDate.seconds * 1000) : null;
              return task.status !== "Completed" && taskEndDate && taskEndDate < now;
            });
            break;
          // Additional cases as needed
        }
  
        isDateValid = taskDate && taskDate >= startDate && taskDate <= endDate;
      }
  
      return isStatusValid && isDateValid;
    });
  
    setTasks(filteredTasks);
  };
  
  
  
  const getStatusClass = (status) => {
    switch (status) {
      case "Not Started":
        return "statusIndicator statusNotStarted";
      case "In Progress":
        return "statusIndicator statusInProgress";
      case "Completed":
        return "statusIndicator statusCompleted";
      default:
        return "statusIndicator";
    }
  };

  const handleStatusClick = async (task, event) => {
    event.stopPropagation(); // Prevent triggering the task's onClick event
  
    let newStatus;
    switch (task.status) {
      case 'Not Started':
        newStatus = 'In Progress';
        break;
      case 'In Progress':
        newStatus = 'Completed';
        break;
      case 'Completed':
        newStatus = 'Not Started';
        break;
      default:
        newStatus = 'Not Started';
    }
  
    await updateTaskStatus(task.id, newStatus); // Update in database
    setTasks(tasks.map(g => g.id === task.id ? { ...g, status: newStatus } : g));
  };
  
  const updateTaskStatus = async (taskId, newStatus) => {
    const taskRef = doc(db, 'Desktop', taskId);
    try {
      await updateDoc(taskRef, { status: newStatus });
    } catch (error) {
      console.error("Error updating status: ", error);
    }
  };
  

  return (
    <div>
      <div className="topBar">
        <select
          className="taskDropdown"
          value={dropdownValue}
          onChange={handleDropdownChange}
        >
          <option value="all">All</option>
          <option value="today">Today</option>
          <option value="tomorrow">Tomorrow</option>
          <option value="yesterday">Yesterday</option>
          <option value="important">Important</option>
          <option value="forgotten">Forgotten</option>
        </select>

        <label>
  <input
    type="checkbox"
    name="Not Started"
    onChange={handleCheckboxChange}
    checked={showNotStarted}
  />
  Not Started
</label>

<label>
  <input
    type="checkbox"
    name="In Progress"
    onChange={handleCheckboxChange}
    checked={showInProgress}
  />
  In Progress
</label>

<label>
  <input
    type="checkbox"
    name="Completed"
    onChange={handleCheckboxChange}
    checked={showCompleted}
  />
  Completed
</label>

        <button className="smallbutton" onClick={() => navigate("/add")}>
          Add Task
        </button>
        <button className="smallbutton" onClick={toggleView}>
          {DeskOrTasksView === "DesktopView" ? "Desk" : "Task"}
        </button>
      </div>

      <div className="heroSection">
        <h1>Welcome to Your Task Manager</h1>
        <p>Manage your tasks efficiently.</p>
        {pathHistory.length > 1 && (
  <button className="upOneLevelButton" onClick={handleUpOneLevel}>
    Up One Level
  </button>
)}
        <div className="taskContainer">
          {tasks.map((task, index) => (
            <button
              key={index}
              className="taskButton"
              onClick={() => handleTaskClick(task.path)}
            >
              <div className="taskTitle">{task.title}</div>
              <div className="taskDetails">
                <div>Name: {task.fileName ? task.fileName : "N/A"}</div>
                <div>
                  Start:{" "}
                  {task.startDate
                    ? new Date(task.startDate.seconds * 1000).toLocaleString()
                    : "N/A"}
                </div>
                <div>
                  End:{" "}
                  {task.endDate
                    ? new Date(task.endDate.seconds * 1000).toLocaleString()
                    : "N/A"}
                </div>
              </div>
              <div
                className={getStatusClass(task.status)}
                onClick={(event) => event.stopPropagation()} // Prevent triggering the button's onClick event
              ></div>
              <button
                key={index}
                className="go4itButton"
                onClick={() => handleGoClick(task.path)}
              >
                Go4it
              </button>
              <button
                key={index}
                className="Duplicate"
                onClick={() => handleDuplicate(task)}
                >
                Dupelicate
              </button>
              <div
      style={getStatusStyle(task.status)}
      onClick={(event) => handleStatusClick(task, event)}
    ></div>
            </button>
          ))}
        </div>
      </div>
      {/* Rest of your component */}
    </div>
  );
}

export default Tasks;

// FileHelpers.js
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { auth } from "../../firebase"; // Adjust the path as needed

const db = getFirestore();
export const addNewFile = async (fileName, currentPath) => {
  if (!fileName) {
    alert("Please enter a file name.");
    return;
  }

  try {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      alert("No user logged in.");
      return;
    }

    const username = auth.currentUser?.displayName || "unknown_user";
    const randomString = Math.random().toString(36).substring(2, 14);
    const newPath =
      currentPath === "/desktop"
        ? `/desktop/u/${username}/${fileName}-${randomString}`
        : `${currentPath}/${fileName}-${randomString}`;

    const fileID = `/${fileName}-${randomString}`;

    // Determine the parentFileID based on currentPath
    let parentFileID;
    if (currentPath === "/desktop") {
      parentFileID = "desktop";
    } else {
      // Extract the parentFileID from currentPath
      // Assuming the fileID is the last segment of the path
      const pathSegments = currentPath.split("/");
      parentFileID = pathSegments[pathSegments.length - 1];
    }
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
    const randomDigits = Math.floor(100000000000 + Math.random() * 900000000000);

    await addDoc(collection(db, "Desktop"), {
      userId: userId,
      fileName: fileName,
      content: "",
      path: newPath,
      createdAt: new Date(),
      lastUpdated: new Date(),
      public: "private",
      currentPath: currentPath,
      type: "txt",
      fileID: fileID,
      parentFileID: parentFileID, // Adding parentFileID to the document
      username: username,
      createdAt: currentDate,

      startDate: currentDate,
      endDate: currentDate,
      allDay: true,
      location: "",
      description: "",
      category: "",
      priority: "",
      reminder: "",
      favorite: false,
      reminderAlert: currentDate,
      linkURL: "",
      linkFile: null,
      repeat: "None",
      assignTo: "",
      status: "Not Started",
      InAppCategory: "",
      docLink: "",
      tasksParent: "Alltasks"
      
    });

    alert("File added successfully!");
  } catch (error) {
    console.error(error);
    alert("Failed to add file. Please try again later.");
  }
};

import React, { useState, useEffect } from 'react';
import '../CSS/MyRoutinesScreen.css'; 
import { useNavigate } from 'react-router-dom';

const MyRoutinesScreen = () => {
  const [routines, setRoutines] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadedRoutines = JSON.parse(localStorage.getItem('routines') || '[]');
    setRoutines(loadedRoutines);
  }, []);

  const handlePressRoutine = (routineId) => {
    navigate(`/manage-steps/${routineId}`);
  };

  return (
    <div className="my-routines-container">
      {routines.map(routine => (
        <div key={routine.id} className="routine-item" onClick={() => handlePressRoutine(routine.id)}>
          <span className="routine-logo">⟳</span>
          <h3 className="routine-title">{routine.name}</h3>
          {routine.steps.map((step, index) => (
            <p key={index} className="routine-step">{`${step.name}: ${step.duration} minutes`}</p>
          ))}
        </div>
      ))}
      <button className="create-routine-button" onClick={() => navigate('/routine-editor')}>Create Routine</button>
    </div>
  );
};

export default MyRoutinesScreen;

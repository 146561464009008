export const getFileIcon = (type) => {
    switch (type) {
      case "txt":
        return "📄"; // Text document icon
      case "Folder":
        return "📁"; // Folder icon
      case "Routine":
        return "♻️"; // Recycle icon for Routine
      case "Habbit":
        return "🚀"; // Rocket icon for Habit
      case "Contact":
        return "👤"; // Icon for Contact
      case "Favorite":
        return "⭐"; // Star icon for Favorite
      case "note":
        return "📝"; // Pencil and paper icon for Note
        case "File":
          return "📁"; // Folder icon for File
      case "Table":
          return "📊"; // Bar chart icon for Table
      case "Emotion":
          return "😊"; // Smiling face icon for Emotion
      case "Journal":
          return "📓"; // Notebook icon for Journal
      case "Thought":
          return "💭"; // Thought bubble icon for Thought
      case "Knowledge":
          return "📚"; // Books icon for Knowledge
      case "Project":
          return "🏗️"; // Construction icon for Project
      case "Task":
          return "✅"; // Check mark icon for Task
      case "Contract":
          return "📜"; // Scroll icon for Contract
      case "Agreement":
          return "🤝"; // Handshake icon for Agreement
      case "Post":
          return "📬"; // Mailbox icon for Post
      case "Image":
          return "🖼️"; // Framed picture icon for Image
      case "Video":
          return "📹"; // Video camera icon for Video
      case "Audio":
          return "🔊"; // Speaker icon for Audio
      case "Code":
          return "💻"; // Laptop icon for Code
      case "Comment":
          return "💬"; // Speech bubble icon for Comment
      case "Poll":
          return "📊"; // Bar chart icon for Poll
      case "Question":
          return "❓"; // Question mark icon for Question
      case "Answer":
          return "✔️"; // Check mark icon for Answer
      case "Review":
          return "🔍"; // Magnifying glass icon for Review
      case "Rating":
          return "⭐"; // Star icon for Rating
      case "Action":
          return "🎬"; // Clapper board icon for Action
      case "Ad":
          return "🛒"; // Shopping cart icon for Ad
      case "Situation":
          return "🌐"; // Globe icon for Situation
      case "Complaint":
          return "🗣️"; // Speaking head icon for Complaint
      case "PublicMessage":
          return "📢"; // Loudspeaker icon for Public Message
      case "ChatRoom":
          return "💬"; // Speech bubble icon for ChatRoom      
          case "Chat":
            return "💬"; // Speech bubble icon for Chat
        case "Message":
            return "📧"; // Envelope icon for Message
        case "Notification":
            return "🔔"; // Bell icon for Notification
        case "Reminder":
            return "🕒"; // Clock icon for Reminder
        case "Dream":
            return "🌙"; // Crescent moon icon for Dream
        case "Idea":
            return "💡"; // Light bulb icon for Idea
        case "Plan":
            return "📅"; // Calendar icon for Plan
        case "Diary":
            return "📔"; // Notebook with decorative cover icon for Diary
        case "Meditation":
            return "🧘"; // Person in lotus position icon for Meditation
        case "Prayer":
            return "🙏"; // Folded hands icon for Prayer
        case "Wish":
            return "🌠"; // Shooting star icon for Wish
        case "Sport":
            return "⚽"; // Soccer ball icon for Sport
        case "Exercise":
            return "🏋️"; // Weight lifter icon for Exercise
        case "Food":
            return "🍔"; // Hamburger icon for Food
        case "Drink":
            return "🍹"; // Tropical drink icon for Drink
        case "Medicine":
            return "💊"; // Pill icon for Medicine
        case "Mantra":
            return "🕉️"; // Om symbol icon for Mantra
        case "Affirmation":
            return "🌈"; // Rainbow icon for Affirmation
        case "Quote":
            return "📜"; // Scroll icon for Quo
        case "Story":
            return "📚"; // Books icon for Story
        case "Poem":
            return "🖋️"; // Fountain pen icon for Poem
        case "Song":
            return "🎵"; // Musical note icon for Song
        case "Movie":
            return "🎬"; // Clapper board icon for Movie
        case "Series":
            return "📺"; // Television icon for Series
        case "Book":
            return "📖"; // Open book icon for Book        
            case "Article":
              return "📰"; // Newspaper icon for Article
          case "Blog":
              return "✍️"; // Writing hand icon for Blog
          case "Website":
              return "🌐"; // Globe icon for Website
          case "App":
              return "📱"; // Mobile phone icon for App
          case "Game":
              return "🎮"; // Video game controller icon for Game
          case "Subscription":
              return "🔖"; // Bookmark tab icon for Subscription
          case "Service":
              return "🔧"; // Wrench icon for Service
          case "Product":
              return "📦"; // Package icon for Product
          case "Business":
              return "🏢"; // Office building icon for Business
          case "Organization":
              return "🏛️"; // Classical building icon for Organization
          case "CRM":
              return "👥"; // Busts in silhouette icon for CRM
          case "ERP":
              return "📈"; // Chart increasing icon for ERP
          case "ProjectManagement":
              return "📋"; // Clipboard icon for Project Management
          case "TaskManagement":
              return "📝"; // Memo icon for Task Management
          case "TimeManagement":
              return "⏱️"; // Stopwatch icon for Time Management
          case "Calendar":
              return "📆"; // Tear-off calendar icon for Calendar
          case "Finance":
              return "💰"; // Money bag icon for Finance
          case "Skill":
              return "🛠️"; // Hammer and wrench icon for Skill
          case "Education":
              return "🎓"; // Graduation cap icon for Education
          case "Job":
              return "💼"; // Briefcase icon for Job
          case "Rule":
              return "📏"; // Straight ruler icon for Rule
          case "Lexicon":
              return "📖"; // Open book icon for Lexicon
          case "Humor":
              return "😄"; // Grinning face with smiling eyes icon for Humor
          case "Joke":
              return "🤡"; // Clown face icon for Joke
          case "Meme":
              return "🐸"; // Frog face icon for Meme (commonly associated with memes)
          case "Gif":
              return "💫"; // Dizzy icon for Gif (indicating motion/animation)
          case "Spirituality":
              return "🕉️"; // Om icon for Spirituality          
              case "Religion":
                return "🛐"; // Place of worship icon for Religion
            case "Philosophy":
                return "🤔"; // Thinking face icon for Philosophy
            case "Psychology":
                return "🧠"; // Brain icon for Psychology
            case "Sociology":
                return "👥"; // Silhouette of two people icon for Sociology
            case "Economy":
                return "💹"; // Chart with upwards trend and yen sign icon for Economy
            case "Politics":
                return "🏛️"; // Classical building icon for Politics
            case "Science":
                return "🔬"; // Microscope icon for Science
            case "Technology":
                return "💻"; // Laptop icon for Technology
            case "Art":
                return "🎨"; // Artist palette icon for Art
            case "Music":
                return "🎵"; // Music note icon for Music
            case "AIAgent":
                return "🤖"; // Robot face icon for AI Agent
            case "Robot":
                return "🦾"; // Mechanical arm icon for Robot
            case "Machine":
                return "⚙️"; // Gear icon for Machine
            case "Human":
                return "🧑"; // Person icon for Human
            case "Animal":
                return "🐾"; // Paw prints icon for Animal
            case "Plant":
                return "🌱"; // Seedling icon for Plant
            case "Mineral":
                return "💎"; // Gem stone icon for Mineral
            case "Diet":
                return "🥗"; // Green salad icon for Diet
            case "Health":
                return "❤️‍🩹"; // Mending heart icon for Health
            case "Fitness":
                return "🏋️"; // Person lifting weights icon for Fitness
            case "Beauty":
                return "💄"; // Lipstick icon for Beauty
            case "Fashion":
                return "👗"; // Dress icon for Fashion
            case "Style":
                return "🕶️"; // Sunglasses icon for Style
            case "Travel":
                return "✈️"; // Airplane icon for Travel
            case "Tourism":
                return "🗺️"; // World map icon for Tourism
            case "Culture":
                return "🏺"; // Amphora icon for Culture
            case "Language":
                return "📚"; // Books icon for Language
            case "History":
                return "🏰"; // Castle icon for History
            case "Geography":
                return "🌍"; // Globe showing Europe-Africa icon for Geography
            case "Math":
                return "➗"; // Division sign icon for Math
            case "Physics":
                return "🌌"; // Milky Way icon for Physics
            case "Chemistry":
                return "🧪"; // Test tube icon for Chemistry
            case "Biology":
                return "🔬"; // Microscope icon for Biology
            case "Astronomy":
                return "🔭"; // Telescope icon for Astronomy
            case "Astrology":
                return "♌"; // Leo astrological sign icon for Astrology
            case "Mythology":
                return "🐉"; // Dragon icon for Mythology
            case "Folklore":
                return "🧚"; // Fairy icon for Folklore
            case "Fiction":
                return "📖"; // Open book icon for Fiction
            case "NonFiction":
                return "📚"; // Books icon for NonFiction
            case "Novel":
                return "📔"; // Notebook with decorative cover icon for Novel
            case "ShortStory":
                return "📜"; // Scroll icon for Short Story
            case "Slang":
                return "💬"; // Speech balloon icon for Slang
            case "Jargon":
                return "🗣️"; // Speaking head icon for Jargon            
                case "Lingo":
                  return "🗣️"; // Speaking head icon for Lingo
              case "Personality":
                  return "🎭"; // Performing arts mask icon for Personality
              case "Character":
                  return "👤"; // Bust in silhouette icon for Character
              case "Behavior":
                  return "🧐"; // Face with monocle icon for Behavior
              case "Attitude":
                  return "💁"; // Person tipping hand icon for Attitude
              case "Emotion":
                  return "😊"; // Smiling face icon for Emotion
              case "Feeling":
                  return "❤️"; // Heart icon for Feeling
              case "Sense":
                  return "👂"; // Ear icon for Sense
              case "Perception":
                  return "👁️"; // Eye icon for Perception
              case "Intuition":
                  return "🔮"; // Crystal ball icon for Intuition
              case "Instinct":
                  return "🐅"; // Tiger icon for Instinct
              case "Intelligence":
                  return "🧠"; // Brain icon for Intelligence
              case "Creativity":
                  return "🌈"; // Rainbow icon for Creativity
              case "Consciousness":
                  return "💡"; // Light bulb icon for Consciousness
              case "Subconsciousness":
                  return "🌜"; // Crescent moon face icon for Subconsciousness
              case "Unconsciousness":
                  return "💤"; // Zzz icon for Unconsciousness
              case "Memory":
                  return "📔"; // Notebook with decorative cover icon for Memory
              case "Imagination":
                  return "🎨"; // Artist palette icon for Imagination
              case "Dream":
                  return "🛌"; // Person in bed icon for Dream
              case "Coin":
                  return "🪙"; // Coin icon for Coin
              case "Token":
                  return "🔑"; // Key icon for Token
              case "Money":
                  return "💵"; // Banknote with dollar sign icon for Money
              case "Currency":
                  return "💱"; // Currency exchange icon for Currency
              case "Stock":
                  return "📈"; // Chart increasing icon for Stock
              case "Bond":
                  return "🔗"; // Link icon for Bond
              case "Commodity":
                  return "🌾"; // Sheaf of rice icon for Commodity
              case "Derivative":
                  return "♾️"; // Infinity icon for Derivative
              case "Future":
                  return "🔮"; // Crystal ball icon for Future
              case "Option":
                  return "✅"; // Check mark button icon for Option
              case "Swap":
                  return "🔄"; // Counterclockwise arrows button icon for Swap
              case "Insurance":
                  return "🛡️"; // Shield icon for Insurance
              case "Loan":
                  return "💳"; // Credit card icon for Loan
              case "Mortgage":
                  return "🏠"; // House icon for Mortgage
              case "Credit":
                  return "💳"; // Credit card icon for Credit
              case "Debit":
                  return "💳"; // Credit card icon for Debit
              case "Investment":
                  return "📊"; // Bar chart icon for Investment
              case "Saving":
                  return "🐷"; // Piggy bank icon for Saving
              case "Expense":
                  return "💸"; // Money with wings icon for Expense
              case "Income":
                  return "💰"; // Money bag icon for Income
              case "Revenue":
                  return "💹"; // Chart with upwards trend and yen sign icon for Revenue
              case "Profit":
                  return "🤑"; // Money-mouth face icon for Profit
              case "Loss":
                  return "🔻"; // Downward button icon for Loss
              case "Tax":
                  return "🏛️"; // Classical building icon for Tax
              case "Fee":
                  return "💲"; // Heavy dollar sign icon for Fee
              case "Fine":
                  return "📜"; // Scroll icon for Fine
              case "Penalty":
                  return "⚖️"; // Balance scale icon for Penalty
              case "Interest":
                  return "💹"; // Chart with upwards trend and yen sign icon for Interest
              case "Dividend":
                  return "💵"; // Banknote with dollar sign icon for Dividendcase "Wage":
                  return "💵"; // Banknote with dollar sign icon for Wage
              case "Salary":
                  return "🧾"; // Receipt icon for Salary
              case "Bonus":
                  return "🎁"; // Gift box icon for Bonus
              case "Tip":
                  return "💡"; // Light bulb icon for Tip
              case "Commission":
                  return "📈"; // Chart increasing icon for Commission
              case "Royalty":
                  return "👑"; // Crown icon for Royalty
              case "Rent":
                  return "🏠"; // House icon for Rent
              case "Utility":
                  return "🔌"; // Electric plug icon for Utility
              case "Bill":
                  return "🧾"; // Receipt icon for Bill
              case "Fine":
                  return "📜"; // Scroll icon for Fine
              case "Ticket":
                  return "🎟️"; // Admission tickets icon for Ticket
              case "Voucher":
                  return "🎫"; // Ticket icon for Voucher
              case "Coupon":
                  return "✂️"; // Scissors icon for Coupon
              case "Gift":
                  return "🎁"; // Gift box icon for Gift
              case "Donation":
                  return "❤️"; // Heart icon for Donation
              case "Charity":
                  return "🤲"; // Palms up together icon for Charity
              case "Fund":
                  return "💰"; // Money bag icon for Fund
              case "VirtualStock":
                  return "🖥️"; // Desktop computer icon for Virtual Stock
              case "NFT":
                  return "🖼️"; // Framed picture icon for NFT
              case "Crypto":
                  return "💠"; // Diamond with a dot icon for Crypto
              case "Future":
                  return "🔮"; // Crystal ball icon for Future
              case "NLP":
                  return "🧠"; // Brain icon for NLP
              case "Activating Button":
                  return "🔘"; // Radio button icon for Activating Button
              case "Act":
                  return "🎭"; // Performing arts mask icon for Act
              case "Sentence":
                  return "✒️"; // Nib icon for Sentence
              case "Word":
                  return "🔤"; // Input latin letters icon for Word
              case "Letter":
                  return "📧"; // Envelope icon for Letter
              case "Number":
                  return "🔢"; // Input numbers icon for Number
              case "Symbol":
                  return "❓"; // Question mark icon for Symbol
              case "Flow":
                  return "🌊"; // Water wave icon for Flow
              case "Process":
                  return "⚙️"; // Gear icon for Process
              case "System":
                  return "🖥️"; // Desktop computer icon for System
              case "Network":
                  return "🌐"; // Globe with meridians icon for Network
              case "Database":
                  return "💾"; // Floppy disk icon for Database
              case "Algorithm":
                  return "📊"; // Bar chart icon for Algorithm
              case "Model":
                  return "🏭"; // Factory icon for Model
              case "Method":
                  return "🔍"; // Magnifying glass tilted right icon for Method              
                  case "Technique":
                    return "🤹"; // Juggling balls icon for Technique
                case "Tool":
                    return "🔨"; // Hammer icon for Tool
                case "Machine":
                    return "🚀"; // Rocket icon for Machine
                case "Device":
                    return "📱"; // Mobile phone icon for Device
                case "Equipment":
                    return "⚙️"; // Gear icon for Equipment
                case "Material":
                    return "🧱"; // Brick icon for Material
                case "Product":
                    return "📦"; // Package icon for Product
                case "ProCons":
                    return "⚖️"; // Balance scale icon for ProCons
                case "Pros":
                    return "👍"; // Thumbs up icon for Pros
                case "Cons":
                    return "👎"; // Thumbs down icon for Cons
                case "Strength":
                    return "💪"; // Flexed biceps icon for Strength
                case "Weakness":
                    return "🦵"; // Leg icon for Weakness
                case "Opportunity":
                    return "🌟"; // Glowing star icon for Opportunity
                case "Threat":
                    return "⚠️"; // Warning icon for Threat
                case "Risk":
                    return "🎲"; // Dice icon for Risk
                case "Issue":
                    return "❗"; // Exclamation mark icon for Issue
                case "Problem":
                    return "🧩"; // Puzzle piece icon for Problem
                case "Solution":
                    return "🔑"; // Key icon for Solution
                case "Idea":
                    return "💡"; // Light bulb icon for Idea
                case "Linkers":
                    return "🔗"; // Link icon for Linkers
                case "Sale":
                    return "🏷️"; // Label icon for Sale
                case "Buy":
                    return "🛒"; // Shopping cart icon for Buy
                case "Sell":
                    return "💰"; // Money bag icon for Sell
                case "Trade":
                    return "🤝"; // Handshake icon for Trade                
                    case "Exchange":
                      return "🔄"; // Counterclockwise arrows button icon for Exchange
                  case "Market":
                      return "🛒"; // Shopping cart icon for Market
                  case "Industry":
                      return "🏭"; // Factory icon for Industry
                  case "MemoryPalace":
                      return "🏰"; // Castle icon for Memory Palace
                  case "MindMap":
                      return "🧠"; // Brain icon for Mind Map
                  case "FlowChart":
                      return "📊"; // Bar chart icon for Flow Chart
                  case "Tree":
                      return "🌳"; // Deciduous tree icon for Tree
                  case "Graph":
                      return "📈"; // Chart increasing icon for Graph
                  case "Diagram":
                      return "📉"; // Chart decreasing icon for Diagram
                  case "Model":
                      return "🏛️"; // Classical building icon for Model
                  case "Map":
                      return "🗺️"; // World map icon for Map
                  case "Chart":
                      return "📊"; // Bar chart icon for Chart
                  case "Table":
                      return "📋"; // Clipboard icon for Table
                  case "List":
                      return "📝"; // Memo icon for List
                  case "Form":
                      return "📄"; // Page facing up icon for Form
                  case "Document":
                      return "📁"; // File folder icon for Document
                  
            
      default:
        return "📄"; // Default icon for any other type
    }
  };
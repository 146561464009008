import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { auth } from '../../firebase'; // Adjust the path as needed
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import default styles
import './CalendarStyles.css'; // Your custom calendar styles


function GoalsScreen() {
    const [goals, setGoals] = useState([]);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const db = getFirestore();
    const [date, setDate] = useState(new Date());
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        setUser(currentUser);
        fetchGoals(currentUser.uid);
      } else {
        alert('No user logged in.');
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const fetchGoals = async (userId) => {
    const q = query(collection(db, 'Desktop'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    setGoals(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
};

  const handleGoalClick = (path) => {
    navigate(path);
  };
  const onCalendarChange = newDate => {
    setDate(newDate);
  };

  const updateGoalStatus = async (goalId, newStatus) => {
    const goalRef = doc(db, 'Desktop', goalId);
    try {
        await updateDoc(goalRef, { status: newStatus });
    } catch (error) {
        console.error("Error updating status: ", error);
    }
};

  const generateTimeBlocks = () => {
    let timeBlocks = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 30) { // For half-hour blocks
        const timeString = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        timeBlocks.push(timeString);
      }
    }
    return timeBlocks;
  };

  const getStatusStyle = (status) => {
    switch (status) {
        case 'Not Started':
            return { backgroundColor: 'red', borderRadius: '50%', width: '15px', height: '15px' };
        case 'In Progress':
            return { backgroundColor: 'green', borderRadius: '50%', width: '15px', height: '15px' };
        case 'Completed':
            return { backgroundColor: 'blue', borderRadius: '50%', width: '15px', height: '15px' };
        default:
            return {};
    }
};


const handleStatusClick = async (goal, event) => {
  event.stopPropagation(); // Prevent triggering the goal's onClick event

  let newStatus;
  switch (goal.status) {
      case 'Not Started':
          newStatus = 'In Progress';
          break;
      case 'In Progress':
          newStatus = 'Completed';
          break;
      case 'Completed':
          newStatus = 'Not Started';
          break;
      default:
          newStatus = 'Not Started';
  }

  await updateGoalStatus(goal.id, newStatus); // Update in database

  // Update the local state to reflect the change
  setGoals(goals.map(g => g.id === goal.id ? { ...g, status: newStatus } : g));
};



  const renderGoalsForSelectedDate = () => {
    const formattedSelectedDate = date.toLocaleDateString('he-IL');
    const goalsForDate = goals
      .filter(goal => 
        goal.startDate && goal.endDate && // Check if startDate and endDate exist
        new Date(goal.startDate.seconds * 1000).toLocaleDateString('he-IL') === formattedSelectedDate
      )
      .sort((a, b) => a.startDate.seconds - b.startDate.seconds); // Sort by time
  
    return goalsForDate.map((goal, index) => (
      <button 
        key={index} 
        onClick={() => handleGoalClick(goal.path)}
        style={{ margin: '5px', display: 'block', textAlign: 'center' }} // Add styling as needed
      >
        <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign:'center' }}>{goal.fileName}</div>
        <div 
                    style={getStatusStyle(goal.status)}
                    onClick={(event) => handleStatusClick(goal, event)} // Add onClick event
                ></div>
                        <div style={{ fontSize: '12px' }}>
          Start: {goal.startDate ? new Date(goal.startDate.seconds * 1000).toLocaleTimeString() : 'N/A'}
        </div>
        <div style={{ fontSize: '12px' }}>
          End: {goal.endDate ? new Date(goal.endDate.seconds * 1000).toLocaleTimeString() : 'N/A'}
        </div>
      </button>
    ));
  };
  
  


  if (!user) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <div>
              <h1>My Goals</h1>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>

        <Calendar
  onChange={onCalendarChange}
  value={date}
  calendarType="US" // This sets the first day of the week to Sunday
/>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>

      <h2>Goals for {date.toDateString()}</h2>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
  <ul className="goals-list">
    {renderGoalsForSelectedDate()}
  </ul>
</div>

    </div>
  );
}

export default GoalsScreen;

import { getFirestore, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { auth } from '../../firebase'; // Adjust the path as needed

const db = getFirestore();

export const addNewLink = async (pageName) => {
  if (!pageName) {
    alert('Please enter a page name.');
    return;
  }

  try {
    const userLinks = await fetchUserLinks();
    const existingPage = userLinks.find(link => link.pageName === pageName);

    if (existingPage) {
      alert('You already have a page with this name. Please choose a different name.');
      return;
    }

    const userEmail = auth.currentUser?.email || 'No email available';
    const username = auth.currentUser?.displayName || 'unknown_user';
    const path = `/link/u/${username}/${pageName}`;

    await addDoc(collection(db, 'links'), {
      userId: auth.currentUser.uid,
      username: username,
      pageName: pageName,
      path: path,
      OwnedEmail: userEmail,
      ...linkFields

    });
    alert('Page added successfully!');
  } catch (error) {
    console.error(error);
    alert('Failed to add page. Please try again later.');
  }
};

const linkFields = {};
for (let i = 1; i <= 10; i++) {
  linkFields[`link${i}`] = [null, null]; // [link name, link itself]
}

const fetchUserLinks = async () => {
  const userId = auth.currentUser?.uid;
  if (!userId) {
    alert('No user logged in.');
    return [];
  }

  const q = query(collection(db, 'links'), where('userId', '==', userId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

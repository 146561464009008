// documentService.js
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

export const updateDocumentService = async (docId, content) => {
  if (!docId) {
    console.error("No document ID available for update.");
    return;
  }

  try {
    const docRef = doc(db, "Desktop", docId);
    const newLastUpdated = new Date();
    await updateDoc(docRef, {
      content: content,
      lastUpdated: newLastUpdated,
    });
    return newLastUpdated; // Return the updated last updated date
  } catch (error) {
    console.error("Error updating document:", error);
    throw error; // Throw the error for handling in the component
  }
};

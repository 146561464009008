import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import '../CSS/GoalView.css'; // Import the CSS file


function GoalView() {
  const { username, goalid } = useParams();
  const [goalData, setGoalData] = useState(null);
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const fetchGoalData = async () => {
      const goalPath = `/goal/u/${username}/${goalid}`;
      const q = query(collection(db, 'goals'), where('path', '==', goalPath));
      const goalSnapshot = await getDocs(q);

      if (!goalSnapshot.empty) {
        const goalDocument = goalSnapshot.docs[0];
        setGoalData(goalDocument.data());
      }
    };

    fetchGoalData();
  }, [username, goalid]);

  if (!goalData) {
    return <div>Loading goal...</div>;
  }

  const textFieldStyle = {
    border: 'none', // Example: No border for the text field
    backgroundColor: '#f0f0f0', // Light grey background
    padding: '10px', // Padding inside the text field
    width: '40%', // Full width
    marginBottom: '10px', // Space between fields
    // Add any additional styling you need
  };

  return (
    <div className="goal-view-container">
      <h1 className="goal-view-header">{goalData.goalName}</h1>
      <div className="goal-view-field">
  <label>Created At:</label>
  <span style={{ fontSize: 'small' }}>
    {goalData.createdAt.toDate().toLocaleString()}
  </span>
</div>

      {/* Repeat for other goal details */}
      <div className="goal-view-field"><label>Goal Title:</label> <input type="text" value={goalData.title} readOnly /></div>
      <div className="goal-view-field-group">

      <div className="goal-view-field-startend">
  <label style={{fontSize:'12px'}}>Start Date:</label>
  <button className="date-button">
    {goalData.startDate.toDate().toLocaleString()}
  </button>
</div>
<div className="goal-view-field-startend">
  <label style={{fontSize:'12px'}}>end Date:</label>
  <button className="date-button">
    {goalData.endDate.toDate().toLocaleString()}
  </button>
</div>


      </div>

      <div className="goal-view-field"><label>Goal Description:</label> <input type="text" value={goalData.description} readOnly /></div>
      <div className="goal-view-field"><label>Goal Category:</label> <input type="text" value={goalData.category} readOnly /></div>
        <div className="goal-view-field"><label>Goal Priority:</label> <input type="text" value={goalData.priority} readOnly /></div>
        <div className="goal-view-field"><label>Goal Reminder:</label> <input type="text" value={goalData.reminder} readOnly /></div>
        <div className="goal-view-field"><label>Goal Favorite:</label> <input type="text" value={goalData.favorite} readOnly /></div>
        <div className="goal-view-field"><label>Goal Reminder Alert:</label> <input type="text" value={goalData.reminderAlert} readOnly /></div>
        <div className="goal-view-field"><label>Goal Link URL:</label> <input type="text" value={goalData.linkURL} readOnly /></div>
        <div className="goal-view-field"><label>Goal Link File:</label> <input type="text" value={goalData.linkFile} readOnly /></div>
        <div className="goal-view-field"><label>Goal Repeat:</label> <input type="text" value={goalData.repeat} readOnly /></div>
        <div className="goal-view-field"><label>Goal Assign To:</label> <input type="text" value={goalData.assignTo} readOnly /></div>
        <div className="goal-view-field"><label>Goal Status:</label> <input type="text" value={goalData.status} readOnly /></div>
        <div className="goal-view-field"><label>Goal In App Category:</label> <input type="text" value={goalData.InAppCategory} readOnly /></div>
        <div className="goal-view-field"><label>Goal Doc Link:</label> <input type="text" value={goalData.docLink} readOnly /></div>
     <div className="goal-view-field"><label>Goal Start Date:</label> <input type="text" value={goalData.startDate} readOnly /></div>
        <div className="goal-view-field"><label>Goal All Day:</label> <input type="text" value={goalData.allDay} readOnly /></div>
        <div className="goal-view-field"><label>Goal Location:</label> <input type="text" value={goalData.location} readOnly /></div>
        <div className="goal-view-field"><label>Goal Username:</label> <input type="text" value={goalData.username} readOnly /></div>
        <div className="goal-view-field"><label>Goal ID:</label> <input type="text" value={goalData.goalID} readOnly /></div>
        <div className="goal-view-field"><label>Goal Path:</label> <input type="text" value={goalData.path} readOnly /></div>
        <div className="goal-view-field"><label>Goal Created At:</label> <input type="text" value={goalData.createdAt} readOnly /></div>
        
      {/* ... and so on for the rest of the goal details ... */}
      {/* Add additional fields as needed */}
      <button className="goal-view-back-button" onClick={() => navigate(-1)}>Back</button>
    </div>
  );
}


export default GoalView;

import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import './LoggedIn/CSS/App.css';
import { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from './firebase';
import Home from './LoggedIn/Home';
import NavBar from './LoggedIn/NavBar';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Register from './Register'; // Import the Register component
import MyProfile from './LoggedIn/MyProfile';
import MyProfilePage from './LoggedIn/MyProfilePage'
import useAuth from './LoggedIn/Components/useAuth'; // Import the custom hook
import LogoutButton from './LoggedIn/Components/LogoutButton'; // Import the LogoutButton component
import UsersPages from './LoggedIn/Screens/UsersPages'; // Import the UsersPages component
import UsersProfiles from './LoggedIn/Screens/UsersProfiles'; // Import the UsersProfiles component
import GoalView from './LoggedIn/Screens/GoalView'; // Adjust the import path as needed
import GoalsScreen from './LoggedIn/Screens/GoalsScreen'; // Adjust the import path as needed
import Desktop from './LoggedIn/Screens/Desktop'; // Adjust the import path as needed
import DeskFile from './LoggedIn/Screens/DeskFile'; // Adjust the import path as needed
import MyRoutines from './LoggedIn/Screens/MyRoutines';
import RoutineEditorScreen from './LoggedIn/Screens/RoutineEditorScreen';
import Header from './LoggedIn/Components/Header';
import Money from './LoggedIn/Screens/Money';
import Footer from './LoggedIn/Components/Footer';
import Web2 from './LoggedIn/Screens/Web2';
import Tasks from './LoggedIn/Screens/Tasks';
import TasksPopup from './LoggedIn/Components/TasksPopup';

function App() {
  const [username, setUsername] = useState(''); // State for username

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isLoggedIn, setIsLoggedIn } = useAuth(); // Use the custom hook
  const [authInitializing, setAuthInitializing] = useState(true); // New state for auth initialization

  const [showTasksPopup, setShowTasksPopup] = useState(false);

  const toggleTasksPopup = () => {
      setShowTasksPopup(!showTasksPopup);
  };


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
      setAuthInitializing(false); // Set to false once we know the auth state
    });

    return () => unsubscribe();
  }, []);
      
  const login = (e) => {
    e.preventDefault();
    const email = `${username}@veryfied.me`; // Construct email from username
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        setIsLoggedIn(true);
      })
      .catch((error) => alert(error.message));
  };

  if (authInitializing) {
    return <div>Loading...</div>; // Show loading state while auth is initializing
  }

  return (
    <Router>
      <div className="App">
        <header className="App-header">
      {/**{isLoggedIn && <Header />} */}  
<div style={{height:'25px'}}></div>
          {!isLoggedIn && (
            <>
              <img src={logo} className="App-logo" alt="logo" />
              <h1>Login / Register</h1>
            </>
          )}
          {/* Routes and other elements */}
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/myprofile" element={<MyProfilePage />} />
            <Route path="/link/u/:username/:pagename" element={<UsersPages />} />
            <Route path="/u/:username" element={<UsersProfiles />} /> {/* New route */}
            <Route path="/goal/u/:username/:goalid" element={<GoalView />} />
            <Route path="/my-goals" element={<GoalsScreen />} />
            <Route path="/desktop/*" element={<Desktop />} />
            <Route path="/desktop/u/*" element={<DeskFile />} />
            <Route path="/my-routines" element={<MyRoutines />} />
            <Route path="/routine-editor" element={<RoutineEditorScreen />} />
            <Route path="/money" element={<Money />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/tasks/:folderName" element={<Tasks />} />

            <Route path="/web2" element={<Web2 />} />
            <Route path="/web2/*" element={<Web2 />} />

            <Route path="/" element={
              <>
                {isLoggedIn ? (
                  <>
                    {/* Display user interface when logged in */}
                    <MyProfile />
                    <Home />
                  </>
                ) : (
                  <div>
                    {/* Login form */}
                    <form onSubmit={login}>
                      <input type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" />
                      <div></div>
                      <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                      <div></div>
                      <button type="submit">Login</button>
                    </form>
                    <Link to="/register">Go to Register</Link>
                  </div>
                )}
              </>
            } />
          </Routes>
        </header>
       {/** <LogoutButton />*/}
 {/**     {isLoggedIn && <NavBar onToggleTasksPopup={toggleTasksPopup} />} */}
                
                {showTasksPopup && <TasksPopup onClose={() => setShowTasksPopup(false)} />}
{/** <Footer /> */}
      </div>
    </Router>
  );
}

export default App;



import React from 'react';
import { useNavigate } from 'react-router-dom';
import MyProfilePage from './MyProfilePage';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

function MyProfile() {
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate('/myprofile');
  };

  return (
    
    <div>

    </div>
  );
}

// Style for the circular button
const circleButtonStyle = {
  borderRadius: '50%', // Make it circular
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  cursor: 'pointer',
  background: '#4CAF50', // Example color
  color: 'white',
  fontSize: '14px'
};

export default MyProfile;

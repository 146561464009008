import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../CSS/Web2.css";
import { getFirestore, doc, setDoc, getDocs, collection, query, where } from "firebase/firestore";
import { auth } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faEdit, faTrash, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useParams } from 'react-router-dom';

const db = getFirestore();

function Web2() {
    const navigate = useNavigate();
    const { web2Path } = useParams(); // Assuming you're using a parameter in your route

    const location = useLocation();
    const [path, setPath] = useState(location.pathname); 
    const [web2url, setWeb2url] = useState("");
    const [web2name, setWeb2name] = useState("");
    const [iframeUrl, setIframeUrl] = useState(''); // URL for the iframe

    const [showAddLinkPop, setShowAddLinkPop] = useState(false);
    const [tabs , setTabs] = useState([]);
    const [showAddTabPop, setShowAddTabPop] = useState(false);
    const [showEditTabPop, setShowEditTabPop] = useState(false);
    const [showDeleteTabPop, setShowDeleteTabPop] = useState(false);
    const [editingTabId, setEditingTabId] = useState(null);
    const [editingTabName, setEditingTabName] = useState(null);
    const [editingTabUrl, setEditingTabUrl] = useState(null);
    const [editingTabIndex, setEditingTabIndex] = useState(null);
    const [editingTab, setEditingTab] = useState(null);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [searchUrl, setSearchUrl] = useState("");


    
    const updateIframeUrl = (newUrl) => {
        setIframeUrl(newUrl);
        navigate(`/web2/${newUrl}`); // Update the URL in the browser's address bar
    };

    const handleAddTabSubmit = (tabName, tabUrl) => {
        addNewTab(tabName, tabUrl);
        setShowAddTabPop(false);
    }

    const handleEditTabSubmit = (tabName, tabUrl) => {
        editTab(tabName, tabUrl);
        setShowEditTabPop(false);
    }

    const handleDeleteTabSubmit = () => {
        deleteTab();
        setShowDeleteTabPop(false);
    }


    const addNewTab = async (tabName, tabUrl) => {
        if (!auth.currentUser || !path) return;
        const tabDoc = doc(db, "web2", auth.currentUser.uid + path + tabName);
        try {
            await setDoc(tabDoc, {
                name: tabName,
                url: tabUrl,
                path,
                uid: auth.currentUser.uid,
            });
            alert("Tab added successfully.");
        } catch (error) {
            console.error("Error adding tab: ", error);
            alert("Error adding tab.");
        }
        setShowAddTabPop(false);
    };

    const editTab = async (tabName, tabUrl) => {
        if (!auth.currentUser || !path) return;
        const tabDoc = doc(db, "web2", auth.currentUser.uid + path + tabName);
        try {
            await setDoc(tabDoc, {
                name: tabName,
                url: tabUrl,
                path,
                uid: auth.currentUser.uid,
            });
            alert("Tab edited successfully.");
        } catch (error) {
            console.error("Error editing tab: ", error);
            alert("Error editing tab.");
        }
        setShowEditTabPop(false);
    };


    const deleteTab = async () => {
        if (!auth.currentUser || !path) return;
        const tabDoc = doc(db, "web2", auth.currentUser.uid + path + editingTabName);
        try {
            await setDoc(tabDoc, {
                name: editingTabName,
                url: editingTabUrl,
                path,
                uid: auth.currentUser.uid,
            });
            alert("Tab deleted successfully.");
        } catch (error) {
            console.error("Error deleting tab: ", error);
            alert("Error deleting tab.");
        }
        setShowDeleteTabPop(false);
    };

    const fetchTabs = async () => {
        const userId = auth.currentUser?.uid;
        if (!userId) {
            alert("No user logged in.");
            return;
        }

        const q = query(
            collection(db, "web2"),
            where("uid", "==", userId),
            where("path", "==", path)
        );
        const querySnapshot = await getDocs(q);
        setTabs(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    const handleOpenAddTabPop = () => {
        setShowAddTabPop(true);
    };

    const handleCloseAddTabPop = () => {
        setShowAddTabPop(false);
    };

    const handleOpenEditTabPop = (tabName, tabUrl, tabIndex) => {
        setEditingTabName(tabName);
        setEditingTabUrl(tabUrl);
        setEditingTabIndex(tabIndex);
        setShowEditTabPop(true);
    };



    const handleCloseEditTabPop = () => {
        setShowEditTabPop(false);
    };


    const handleOpenDeleteTabPop = (tabName, tabUrl, tabIndex) => {
        setEditingTabName(tabName);
        setEditingTabUrl(tabUrl);
        setEditingTabIndex(tabIndex);
        setShowDeleteTabPop(true);
    };

    const handleCloseDeleteTabPop = () => {
        setShowDeleteTabPop(false);
    };

    const handleNavigate = (url) => {
        setIframeUrl(url);
    };

    const handleSearch = () => {
        navigate(searchUrl);
        setShowSearchBar(false);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setPath(location.pathname);
                fetchTabs();
            } else {
                navigate("/");
            }
        });
        return () => unsubscribe();
    }
    , [location]);

    useEffect(() => {
        setPath(location.pathname);
        console.log("Current URL:", location.pathname);
    
        // Split the URL by slashes and remove any empty elements
        const urlParts = location.pathname.split('/').filter(part => part);
    
        // Log each part of the URL
        urlParts.forEach((part, index) => {
            console.log(`Ingredient ${index + 1} of the URL is: ${part}`);
        });

        setIframeUrl("https://facebook.com"); // Set a default URL to test the iframe

    }, [location]);
    
    

    useEffect(() => {
        localStorage.setItem("tabs", JSON.stringify(tabs));
    }
    , [tabs]);

    
    useEffect(() => {
        // Set the initial iFrame URL based on the current path
        if (web2Path) {
            const formattedUrl = web2Path.startsWith('http://') || web2Path.startsWith('https://') 
                ? web2Path 
                : `https://${web2Path}`;
            setIframeUrl(formattedUrl);
        }

        

        // Listener for messages from the iFrame
        const messageListener = (event) => {
            if (event.data.type === 'navigate') {
                updateIframeUrl(event.data.url);
            }
        };


        window.addEventListener('message', messageListener);

        return () => {
            window.removeEventListener('message', messageListener);
        };
    }, [web2Path, navigate]);



    useEffect(() => {
        const fetchTabs = async () => {
            const userId = auth.currentUser?.uid;
            if (!userId) {
                alert("No user logged in.");
                return;
            }
            const q = query(collection(db, "web2"), where("uid", "==", userId));
            const querySnapshot = await getDocs(q);
            setTabs(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        };

        fetchTabs();
    }, [location.pathname]);

    
    const handleCloseAddLinkPop = () => {
        setShowAddLinkPop(false);
    }

    return (

        <div className="web2">

            <div className="web2-header">

                <div className="web2-header-left">
                    <button onClick={handleOpenAddTabPop} className="web2-add-tab-button">
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <button onClick={() => setShowSearchBar(!showSearchBar)} className="web2-search-button">
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                    {showSearchBar && (
                        <div className="web2-search-bar">
                            <input
                                type="text"
                                value={searchUrl}
                                onChange={(e) => setSearchUrl(e.target.value)}
                                className="web2-search-input"
                            />
                            <button onClick={handleSearch} className="web2-search-go-button">GO</button>
                        </div>
                    )}
                </div>
                    </div>
            <div className="web2-body">

            <div className="web2-body-left">
                    {tabs.map((tab, index) => (
                        <div key={index} className="web2-tab">
                            <button onClick={() => handleNavigate(tab.url)} className="web2-tab-button">
                                {tab.name}
                            </button>
                            <button onClick={() => handleOpenEditTabPop(tab.name, tab.url, index)} className="web2-edit-tab-button">
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button onClick={() => handleOpenDeleteTabPop(tab.name, tab.url, index)} className="web2-delete-tab-button">
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    ))}
                </div>
                <div className="web2-body-right">
                <iframe src={iframeUrl} className="web2-iframe" title="Web Viewer" />
                </div>
            </div>
                        

            {showAddTabPop && (

                <div className="web2-add-tab-popup-container">

                    <div className="web2-add-tab-popup">

                        <button onClick={handleCloseAddTabPop} className="web2-add-tab-close-button">
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <h2>Add New Tab</h2>
                        <input
                            type="text"
                            className="web2-add-tab-input"
                            placeholder="Enter Tab Name"
                            value={web2name}
                            onChange={(e) => setWeb2name(e.target.value)}

                        />

                        <input
                            type="text"
                            className="web2-add-tab-input"
                            placeholder="Enter Tab URL"
                            value={web2url}
                            onChange={(e) => setWeb2url(e.target.value)}

                        />
                        <button onClick={() => handleAddTabSubmit(web2name, web2url)} className="web2-add-tab-button">
                            Add Tab
                        </button>
                    </div>
                </div>
            )}
            </div>
    );
}

export default Web2;
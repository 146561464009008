import React, { useState } from 'react';

const getBadgeColor = (verifiedLevel) => {
  const colors = {
    1: 'grey',
    2: 'black',
    3: 'yellow',
    4: 'green',
    5: 'darkgreen',
    6: 'blue',
    7: 'purple',
    8: 'cyan',
  };
  return colors[verifiedLevel] || 'transparent';
};

const VerifiedBadge = ({ verifiedLevel }) => {
  const [isHovered, setIsHovered] = useState(false);

  if (!verifiedLevel) {
    return null;
  }

  const badgeButtonStyle = {
    position: 'absolute',
    bottom: '0',
    right: '10%',
    backgroundColor: getBadgeColor(verifiedLevel),
    color: 'white',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    padding: '0',
    border: '1px solid white',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    zIndex: '2',
  };

  const popupStyle = {
    position: 'absolute',
    bottom: '25px', // Adjust as needed to position the popup above the badge
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#02afff',
    padding: '5px 10px',
    borderRadius: '5px',
    boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
    display: isHovered ? 'block' : 'none', // Show popup only when hovered
    zIndex: '3',
  };

  return (
    <div style={{ position: 'relative' }}> {/* Container for badge and popup */}
      <button 
        style={badgeButtonStyle} 
        onClick={() => {/* Handle click event here */}}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        ✔
      </button>
      <div style={popupStyle}>
        This User Verified level is: {verifiedLevel}
      </div>
    </div>
  );
};

export default VerifiedBadge;

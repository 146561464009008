// FileDuplicateHelpers.js
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { auth } from "../../firebase"; // Adjust the path as needed
import { v4 as uuidv4 } from 'uuid'; // Import UUID library to generate a random string

const db = getFirestore();

export const addNewFile = async (file) => {
  if (!file || !file.currentPath) {
    alert("Invalid file data.");
    return;
  }

  const userId = auth.currentUser?.uid;
  if (!userId) {
    alert("No user logged in.");
    return;
  }

  const username = auth.currentUser?.displayName || "unknown_user";
  const randomString = uuidv4().substring(0, 12); // Generate a 12-character random string
  const duplicateFileID = `${file.fileName}-Dupe${randomString}`; // Create a duplicate file name
  const duplicateFileName = `${file.fileName}-Dupelication`;
  const newPath = `${file.path}/${duplicateFileName}`;

  try {
    await addDoc(collection(db, "Desktop"), {
      fileName: duplicateFileName,
      parentFileID: file.fileName,


      userId: userId,
      content: file.content,
      path: newPath,
      createdAt: new Date(),
      lastUpdated: new Date(),
      public: "private",
      currentPath: file.path,
      type: file.type,
      fileID: duplicateFileID,
      username: username,
      startDate: new Date(),
      endDate: new Date(),
      allDay: file.allDay, 
      location: file.location,
      description: file.description,
      category: file.category,
      priority: file.priority,
      reminder: file.reminder,
      favorite: false,
      reminderAlert: file.reminderAlert,
      linkURL: file.linkURL,
      linkFile: file.linkFile,
      repeat: file.repeat,
      assignTo: file.assignTo,
      status: file.status,
      InAppCategory: file.InAppCategory,
      docLink: file.docLink,
      tasksParent: file.tasksParent
      // Note: You might want to modify or remove certain properties that should not be duplicated directly.
    });

    alert("File duplicated successfully!");
  } catch (error) {
    console.error("Failed to add file. Please try again later.", error);
  }
};

import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { auth } from '../firebase';
import useAuth from './Components/useAuth'; // Import the custom hook
import './CSS/MyProfilePage.css'; // Import the CSS file


function MyProfilePage() {
  const [userProfile, setUserProfile] = useState({
    firstName: '',
    lastName: '',
    username: '',
    realEmail: '',
    phoneNumber: '',
    profilePicture: '',
    instagramLink: '',
    facebookLink: ''
  });
  const db = getFirestore();
  const { isLoggedIn } = useAuth(); // Use the custom hook

  useEffect(() => {
    const fetchUserData = async () => {
      if (isLoggedIn && auth.currentUser) {
        const userId = auth.currentUser.uid;
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setUserProfile(userDoc.data());
        } else {
          console.log('No such document!');
        }
      } else {
        console.log('No user logged in');
      }
    };

    fetchUserData();
  }, [isLoggedIn]); // Depend on isLoggedIn state

  // Function to handle image load error
  const handleImageError = () => {
    console.error("Error loading profile image");
    // Optionally set a default image or handle the error
  };

  return (
    <div className="my-profile-container">
      <h1 className="my-profile-header">My Profile</h1>
      {userProfile.profilePicture ? (
        <img 
          className="profile-img"
          src={userProfile.profilePicture} 
          alt="Profile"
          onError={handleImageError} // Handle image load error
        />
      ) : (
        <p>No profile picture available</p> // Show a message if no picture is available
      )}
      <div className="profile-detail"><strong>Username:</strong> {userProfile.username}</div>
      <div className="profile-detail"><strong>First Name:</strong> {userProfile.firstName}</div>
      <div className="profile-detail"><strong>Last Name:</strong> {userProfile.lastName}</div>
      <div className="profile-detail"><strong>Email:</strong> {userProfile.realEmail}</div>
      <div className="profile-detail"><strong>Phone Number:</strong> {userProfile.phoneNumber}</div>
      <div className="profile-social-links">
        {userProfile.instagramLink && <a href={userProfile.instagramLink}>Instagram</a>}
        {userProfile.facebookLink && <a href={userProfile.facebookLink}>Facebook</a>}
      </div>
    </div>
  );
}


export default MyProfilePage;

import React, { useState, useEffect } from "react";
import { getFileIcon } from "../fileIcons"; // Adjust the import path as necessary
import "../../CSS/TypeFileStyles.css"; // Adjust the import path as necessary


function TypeFile({ onFileTypeSelect }) {



    const productivity = [
        "Folder", "File", "Table", "Journal", "Project", "Task", "Plan", "Diary", "Calendar", "List", "Form", "Document"
    ];

    const communication = [
        "Comment", "Poll", "Question", "Answer", "Chat", "Message", "PublicMessage", "ChatRoom", "Post", "Email", "Newsletter", "Bulletin", "Announcement", "Survey", "Blog", "Website", "SocialMedia"
    ];

    const wellness = [
        "Routine", "Habbit", "Meditation", "Prayer", "Wish", "Idea", "Mantra", "Affirmation", "Skill", "Education", "Job", "MemoryPalace", "MindMap", "FlowChart"
    ];
    
    const creative = [
        "Image", "Video", "Audio", "Song", "Movie", "Series", "Game", "App", "Subscription", "Service", "Product", "Story", "Poem", "Art", "Music"
    ];

    const business = [
        "Business", "Organization", "CRM", "ERP", "Finance", "Investment", "Stock", "Bond", "Loan", "Insurance", "Invoice", "Budget", "Accounting", "Expense", "Revenue", "Profit", "Loss", "Tax", "Fund", "Salary", "Banking", "Trading", "Cryptocurrency", "AssetManagement", "WealthManagement", "FinancialPlanning", "RetirementPlanning", "Portfolio", "StockMarket", "Forex", "Commodity", "Derivative", "MutualFund", "HedgeFund", "Income", "Expanse"
    ];

    const legal = [
        "Contract", "Agreement", "Law", "Regulation", "Policy", "Standard", "Compliance", "Governance", "Jurisdiction", "LegalCase", "Statute", "Ordinance", "Decree", "Mandate", "Protocol", "Convention", "Treaty", "Clause", "Provision"
    ];

    const marketing = [
        "Ad", "Marketing", "Promotion", "Branding", "Campaign", "PublicRelations", "SocialMediaMarketing", "ContentMarketing", "EmailMarketing", "SEO", "PPC", "MarketResearch", "ConsumerBehavior", "Analytics", "Targeting"
    ];

    const health = [
        "Health", "Medicine", "Fitness", "Diet", "Nutrition", "Exercise", "Yoga", "Therapy", "MentalHealth", "Wellness", "SelfCare", "Healing", "Rehabilitation", "Hygiene", "FirstAid", "Treatment", "Prescription", "Symptom", "Diagnosis"
    ];

    const science = [
        "Science", "Technology", "Research", "Data", "Database", "Algorithm", "Code", "Software", "Hardware", "Machine", "Robot", "AIAgent", "Innovation", "Experiment", "Analysis", "Study", "Simulation", "Prototype", "Model", "Engineering", "Invention", "Investigation"
    ];

    const human = [
        "HumanResources", "Recruitment", "TalentManagement", "PerformanceManagement", "EmployeeEngagement", "TrainingDevelopment", "OrganizationalDevelopment", "Leadership", "TeamBuilding", "ConflictResolution", "Negotiation", "DecisionMaking"
    ];


    const documentTypes = [
        "txt", "note", "Document", "Article", "Blog", "Story", "Poem", "Book", "Novel", "ShortStory", "Fiction", "NonFiction", "Review", "Contract", "Agreement", "Plan", "Diary", "Report", "Thesis", "Script", "Code",
        // ... other document types
    ];

    const MediaandEntertainment = [
        "Image", "Video", "Audio", "Song", "Movie", "Series", "Game", "App", "Animation", "Podcast", "Playlist", "Album", "Artwork", "Performance", "Broadcasting"
        // ... other media and entertainment types
    ];

    const CommunicationandSocial = [
        "Comment", "Poll", "Question", "Answer", "Chat", "Message", "PublicMessage", "ChatRoom", "Forum", "Post", "Email", "Newsletter", "Bulletin", "Announcement", "Survey"

    ];

    const BusinessandFinance = [
        "Business", "Organization", "CRM", "ERP", "Finance", "Investment", "Stock", "Bond", "Loan", "Insurance", "Invoice", "Budget", "Accounting", "Expense", "Revenue", "Profit", "Loss", "Tax", "Fund", "Salary"

    ];

    const ProjectandTaskManagement = [
        "Project", "Task", "TaskManagement", "ProjectManagement", "Checklist", "Agenda", "Goal", "Milestone", "Objective", "Strategy", "Operation", "Workflow", "Process", "System", "Method", "Technique", "Procedure", "Practice", "Instruction", "Guideline"

    ];

    const ScienceandTechnology = [
       "AIAgent",  "Algorithm",     "Science", "Technology", "Research", "Data", "Database", "Algorithm", "Code", "Software", "Hardware", "Machine", "Robot", "AIAgent", "Innovation", "Experiment", "Analysis", "Study", "Simulation", "Prototype", "Model", "Engineering", "Invention", "Investigation"
    ];

    const HealthandWellness = [
        "Health", "Medicine", "Fitness", "Diet", "Nutrition", "Exercise", "Yoga", "Meditation", "Therapy", "MentalHealth", "Wellness", "SelfCare", "Healing", "Rehabilitation", "Hygiene", "FirstAid", "Treatment", "Prescription", "Symptom", "Diagnosis"
    ];

    const PersonalDevelopment = [
      "Character",  "Skill", "Education", "Learning", "Training", "Course", "Workshop", "Seminar", "Lecture", "Tutorial", "Guide", "Manual", "Coaching", "Mentorship", "Counseling", "Advice", "Insight", "Reflection", "Discovery", "Realization", "Enlightenment"
    ];

    const CreativeArts = [
        "Art", "Music", "Literature", "Design", "Fashion", "Photography", "Craft", "Sculpture", "Painting", "Drawing", "Writing", "Composing", "Filmmaking", "Acting", "Dancing", "Singing", "Playing", "Directing", "Producing"
    ];

    const LifestyleandHobbies = [
        "Act", "Action", "Hobby", "Sport", "Travel", "Tourism", "Culture", "Language", "Cooking", "Gardening", "DIY", "HomeImprovement", "Decorating", "Collecting", "Gaming", "OutdoorActivity", "Adventure", "Leisure", "Entertainment", "Recreation", "Celebration"
    ];

    const MindandSpirit = [
      "Behavior", "Attitude", "Affirmation", "Activating Button",  "Spirituality", "Religion", "Philosophy", "Psychology", "Sociology", "Ethics", "Morality", "Belief", "Faith", "Ritual", "Practice", "Mindfulness", "Consciousness", "Subconsciousness", "Intuition", "Perception", "Awareness", "Wisdom", "Thought", "Idea", "System1","System2","System3","System4","System5","System0"
    ];

    const DigitalandOnlineContent = [
        "Website", "SocialMedia", "DigitalAsset", "EBook", "OnlineCourse", "Webinar", "Podcast", "BlogPost", "Vlog", "Infographic", "DigitalArt", "ECommerce", "OnlineService", "StreamingContent", "WebApplication", "MobileApp", "SoftwareTool"

    ];

    const LegalandRegulatory = [
        "Law", "Regulation", "Policy", "Standard", "Compliance", "Governance", "Jurisdiction", "LegalCase", "Statute", "Ordinance", "Decree", "Mandate", "Protocol", "Convention", "Treaty", "Agreement", "Contract", "Clause", "Provision"
    ];

    const FinanceandInvestment = [
        "Bill", "Buy","Banking", "Investing", "Trading", "Cryptocurrency", "AssetManagement", "WealthManagement", "FinancialPlanning", "RetirementPlanning", "Portfolio", "StockMarket", "Forex", "Commodity", "Derivative", "MutualFund", "HedgeFund", "Income", "Expanse"
    ];

    const MarketingandAdvertising = [
        "Ad", "Marketing", "Advertising", "Promotion", "Branding", "Campaign", "PublicRelations", "SocialMediaMarketing", "ContentMarketing", "EmailMarketing", "SEO", "PPC", "MarketResearch", "ConsumerBehavior", "Analytics", "Targeting"
    ];

    const HumanResourcesandManagement = [
        "HumanResources", "Recruitment", "TalentManagement", "PerformanceManagement", "EmployeeEngagement", "TrainingDevelopment", "OrganizationalDevelopment", "Leadership", "TeamBuilding", "ConflictResolution", "Negotiation", "DecisionMaking"
    ];

    const RealEstateandProperty = [
        "RealEstate", "Property", "Housing", "Land", "Building", "Construction", "Architecture", "InteriorDesign", "Landscape", "UrbanPlanning", "Development", "Investment", "Mortgage", "Lease", "Rent"
    ];

    const EnvironmentalandNature = [    
        "Animal", "Environment", "Nature", "Ecology", "Sustainability", "Conservation", "ClimateChange", "RenewableEnergy", "NaturalResource", "Wildlife", "Biodiversity", "GreenTechnology", "Pollution", "WasteManagement", "Recycling", "Ecosystem", "Plant"
    ];

    const HistoricalandCultural = [
        "History", "Archaeology", "Anthropology", "Mythology", "Folklore", "Tradition", "Heritage", "Civilization", "Exploration", "Discovery", "Expedition", "Museum", "Gallery", "Archive", "Artifact", "Monument"
    ];

    const AnalyticsandData = [
        "DataAnalysis", "Analytics", "Statistics", "DataScience", "BigData", "MachineLearning", "AI", "DataMining", "PredictiveModeling", "DataVisualization", "BusinessIntelligence", "Dashboard", "Report", "Insight", "Forecast"
    ];




    // Merge the arrays for the fileTypes
    const allFileTypes = [
        ...productivity,
        ...communication,
        ...wellness,
        ...creative,
        ...business,
        ...legal,
        ...marketing,
        ...health,
        ...science,
        ...human,

    ];
    
 

    const getFilteredItems = () => {
        return allFileTypes
            .filter(type => type.toLowerCase().includes(filter.toLowerCase()))
            .filter(type => selectedCategory === "Popular" || type.toLowerCase().includes(selectedCategory.toLowerCase()))
            .map(type => ({
                name: type,
                emoji: getFileIcon(type),
            }));
    };




    const categories = [
        "Popular",
        "Document Types",
        "Media and Entertainment",
        "Communication and Social",
        "Business and Finance",
        "Project and Task Management",
        "Science and Technology",
        "Health and Wellness",
        "Personal Development",
        "Creative Arts",
        "Lifestyle and Hobbies",
        "Mind and Spirit",
        "Digital and Online Content",
        "Legal and Regulatory",
        "Finance and Investment",
        "Marketing and Advertising",
        "Human Resources and Management",
        "Real Estate and Property",
        "Environmental and Nature",
        "Historical and Cultural",
        "Analytics and Data",
    ];

    const categoryMap = {
        "Document Types": documentTypes,
        "Media and Entertainment": MediaandEntertainment,
        "Communication and Social": CommunicationandSocial,
        "Business and Finance": BusinessandFinance,
        "Project and Task Management": ProjectandTaskManagement,
        "Science and Technology": ScienceandTechnology,
        "Health and Wellness": HealthandWellness,
        "Personal Development": PersonalDevelopment,
        "Creative Arts": CreativeArts,
        "Lifestyle and Hobbies": LifestyleandHobbies,
        "Mind and Spirit": MindandSpirit,
        "Digital and Online Content": DigitalandOnlineContent,
        "Legal and Regulatory": LegalandRegulatory,
        "Finance and Investment": FinanceandInvestment,
        "Marketing and Advertising": MarketingandAdvertising,
        "Human Resources and Management": HumanResourcesandManagement,
        "Real Estate and Property": RealEstateandProperty,
        "Environmental and Nature": EnvironmentalandNature,
        "Historical and Cultural": HistoricalandCultural,
        "Analytics and Data": AnalyticsandData,

    };
    
    const initialItems = allFileTypes.map(type => ({
        name: type,
        emoji: getFileIcon(type),
    }));

    const selectFileType = (type) => {
        onFileTypeSelect(type);
    };

    const [items, setItems] = useState(initialItems);
    const [filter, setFilter] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("Popular");
    

    const filterItemsByCategory = (category) => {
        setSelectedCategory(category);
        if (category === "Popular") {
            setItems(initialItems);
        } else {
            const filteredItems = initialItems.filter(item =>
                item.name.toLowerCase().includes(filter.toLowerCase()) &&
                item.name.toLowerCase().includes(category.toLowerCase())
            );
            setItems(filteredItems);
        }
    };


    const filteredItems = getFilteredItems();

    const handleSelectChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    useEffect(() => {
        let filteredItems = [];
    
        if (selectedCategory === "Popular") {
            filteredItems = allFileTypes;
        } else {
            filteredItems = categoryMap[selectedCategory] || [];
        }
    
        filteredItems = filteredItems
            .filter(type => type.toLowerCase().includes(filter.toLowerCase()))
            .map(type => ({
                name: type,
                emoji: getFileIcon(type),
            }));
    
        setItems(filteredItems);
    }, [filter, selectedCategory]);
return (
    <div className="typeFileContainer">
        <div className="header">
            <input
                type="text"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Filter by name"
                className="filterInput"
            />
            <select 
                onChange={handleSelectChange}
                className="categorySelect"
            >
                {categories.map((category, index) => (
                    <option key={index} value={category}>
                        {category}
                    </option>
                ))}
            </select>
        </div>
        <div className="itemsContainer">
            {items.map((item, index) => (
                <button
                    key={index}
                    onClick={() => selectFileType(item.name)}
                    className="itemButton"
                >
                    {item.emoji} {item.name}
                </button>
            ))}
        </div>
    </div>
);
            }

export default TypeFile;


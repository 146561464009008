import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { db, auth } from "../../firebase";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { debounce, set } from "lodash"; // Make sure to install lodash
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Spreadsheet from "react-spreadsheet";
import GoalPopup from "../Components/GoalPopup";
import { getFileIcon } from "../Components/fileIcons";
import { changeDocumentPublicStatus } from "../Components/documentUtils.js";
import { updateDocumentService } from "../Components/documentService"; // Update the import path based on your file structure

import handleGoalClick from "../Components/handleGoalClick";
import { playRoutine, startTimer } from "../Components/Routines";
import TypeFile from "../Components/Desktop/TypeFile";
function DeskFile() {
  const [showGoalPopup, setShowGoalPopup] = useState(false);
  const [selectedGoalDetails, setSelectedGoalDetails] = useState(null);
  const [showTypeFile, setShowTypeFile] = useState(false); // New state for showing TypeFile

  const [currentFileData, setCurrentFileData] = useState({}); // State for storing the current file data
  const [parentFileID, setParentFileID] = useState(null);
  const [spreadsheetData, setSpreadsheetData] = useState([[]]); // Initialize with empty data

  const [documentType, setDocumentType] = useState("Routine"); // Add state for document type

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [lastUpdated, setLastUpdated] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = auth.currentUser?.uid;
  const [docId, setDocId] = useState(null); // State for storing the document ID
  const [documentData, setDocumentData] = useState({}); // State for storing the document data

  const isOwner = documentData.userId === userId;
  const isPublicDocument = documentData.public === "public";
  const [relatedItems, setRelatedItems] = useState([]); // State to hold related items

  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [stepName, setStepName] = useState("");
  const [steps, setSteps] = useState([]);
  const [routineDescription, setRoutineDescription] = useState("");
  const [linkUrl, setLinkUrl] = useState("");

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [timer, setTimer] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [showFinished, setShowFinished] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [filterName, setFilterName] = useState("");
  const currentFileID = location.pathname.split("/").pop();

  const filteredItems = relatedItems.filter((item) => {
    return (
      (filterType ? item.type === filterType : true) &&
      (filterName
        ? item.fileName.toLowerCase().includes(filterName.toLowerCase())
        : true)
    );
  });

  const pauseTimer = () => {
    if (!isPaused) {
      clearInterval(intervalId); // Pause the timer
    } else {
      startTimer(steps[currentStepIndex]); // Resume the timer
    }
    setIsPaused(!isPaused);
  };

  const toggleTypeFile = () => {
    setShowTypeFile(!showTypeFile);
  };


  const handleFileTypeSelect = (type) => {
    setShowTypeFile(false); // Close TypeFile component
    handleDocumentTypeChange({ target: { value: type } }); // Call existing function
  };
  
  const nextStep = () => {
    const nextIndex = currentStepIndex + 1;
    if (nextIndex < steps.length) {
      setCurrentStepIndex(nextIndex);
      startTimer(steps[nextIndex], nextIndex);
    } else {
      finishRoutine();
    }
  };

  const stopRoutine = () => {
    // Stop the timer and reset the play mode
    setIsPlaying(false);
    setCurrentStepIndex(0);
  };

  const skipStep = () => {
    clearInterval(intervalId); // Stop the current timer
    if (currentStepIndex < steps.length - 1) {
      const nextStepIndex = currentStepIndex + 1;
      setCurrentStepIndex(nextStepIndex);
      startTimer(steps[nextStepIndex], nextStepIndex);
    } else {
      // Finish routine if no more steps
      finishRoutine();
    }
  };
  const finishRoutine = () => {
    clearInterval(intervalId); // Stop the timer
    setShowFinished(true);
    setTimeout(() => {
      setIsPlaying(false);
      setShowFinished(false);
      setCurrentStepIndex(0);
      setIsPaused(false);
    }, 2000); // Show 'Finished' for 2 seconds before resetting
  };
  // Remember to clear the interval when the component unmounts
  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);
  const reorderSteps = async (dragIndex, hoverIndex) => {
    const draggedStep = steps[dragIndex];

    let newSteps = [...steps];
    newSteps.splice(dragIndex, 1);
    newSteps.splice(hoverIndex, 0, draggedStep);

    // Update the order property of steps
    newSteps = newSteps.map((step, index) => ({
      ...step,
      order: index + 1,
    }));

    setSteps(newSteps);

    // Update Firestore document
    if (!docId) {
      console.error("No document ID available for updating steps.");
      return;
    }

    try {
      const docRef = doc(db, "Desktop", docId);
      await updateDoc(docRef, { steps: newSteps });
    } catch (error) {
      console.error("Error updating steps:", error);
    }
  };

  const onGoalClick = () => {
    handleGoalClick(currentFileData, setSelectedGoalDetails, setShowGoalPopup);
  };

  const handleStepClick = (step) => {
    // Handle the step click
    // Example: console.log(`Step clicked: ${step.name}`);
  };
  const Step = ({ step, index }) => {
    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: "step",
        item: { index },
        collect: (monitor) => ({
          isDragging: !!monitor.isDragging(),
        }),
      }),
      [index, steps]
    );

    const [, drop] = useDrop(
      () => ({
        accept: "step",
        hover(item, monitor) {
          if (item.index !== index) {
            reorderSteps(item.index, index);
            item.index = index;
          }
        },
      }),
      [steps]
    );

    return (
      <div
        ref={(node) => drag(drop(node))}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <button onClick={() => handleStepClick(step)}>
          Step {step.order}: {step.name} - {step.duration.hours}h{" "}
          {step.duration.minutes}m
        </button>
      </div>
    );
  };
  const addStep = async () => {
    const newStep = {
      name: stepName,
      duration: { hours, minutes, seconds },
      description: routineDescription,
      link: linkUrl,
      order: steps.length + 1,
    };

    // Add step to local state
    const newSteps = [...steps, newStep];
    setSteps(newSteps);

    // Update Firestore document
    if (!docId) {
      console.error("No document ID available for updating steps.");
      return;
    }

    try {
      const docRef = doc(db, "Desktop", docId);
      await updateDoc(docRef, {
        steps: newSteps,
      });
    } catch (error) {
      console.error("Error updating steps:", error);
    }
  };

  const toolbarOptions = {
    blockType: {
      inDropdown: true,
      options: ["Normal", "H1", "H2", "H3", "Blockquote", "Code"],
    },
    fontSize: {
      options: [8, 10, 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72],
    },
    // ... other toolbar options ...
  };
  const extractPlainText = (editorState) => {
    return editorState.getCurrentContent().getPlainText();
  };

  // Declare the updateDocument function before using it in debounce
  // With this
  const updateDocument = async (content) => {
    try {
      const newLastUpdated = await updateDocumentService(docId, content);
      setLastUpdated(newLastUpdated); // Update the last updated state
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };
  const debouncedUpdate = useCallback(
    debounce((content) => {
      updateDocument(content);
    }, 2000),
    [docId]
  ); // Add docId to the dependencies array if updateDocument depends on it

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel(); // Cancel any pending updates when component unmounts
    };
  }, [debouncedUpdate]); // Add debouncedUpdate to the dependencies array

  useEffect(() => {
    console.log(`User navigated to: ${location.pathname}`);
  }, [location]);

  useEffect(() => {
    if (documentType === "Table") {
      // Initialize or fetch spreadsheet data
      const initialData = [
        // Example Data
        [{ value: "ID" }, { value: "Name" }, { value: "Value" }],
        [{ value: 1 }, { value: "Item 1" }, { value: 100 }],
        [{ value: 2 }, { value: "Item 2" }, { value: 150 }],
      ];
      setSpreadsheetData(initialData);
    }
  }, [documentType]);

  useEffect(() => {
    let isMounted = true;

    const initializeEditor = async () => {
      const desktopCollectionRef = collection(db, "Desktop");

      const q = query(
        desktopCollectionRef,
        where("path", "==", location.pathname)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const documentSnapshot = querySnapshot.docs[0];
        const data = documentSnapshot.data();
        setCurrentFileData(data);
        setDocumentData(data);
        setDocId(documentSnapshot.id);
        console.log("File ID:", data.fileID);
        setParentFileID(data.fileID);


        
        if (data.lastUpdated) {
          setLastUpdated(data.lastUpdated.toDate());
        }

        if (data.steps) {
          setSteps(data.steps);
        }

        if (data.type) {
          setDocumentType(data.type);
        }
        // Initialize editor state for both owner and public viewers
        if (data.content) {
          const contentState = convertFromRaw(JSON.parse(data.content));
          setEditorState(EditorState.createWithContent(contentState));
        } else {
          setEditorState(EditorState.createEmpty());
        }
      } else {
        setEditorState(EditorState.createEmpty());
      }
    };

    const fetchRelatedItems = async () => {
      // Assuming path is stored in `location.pathname`
      const q = query(
        collection(db, "Desktop"),
        where("parentFileID", "==", currentFileID)
      );
      const querySnapshot = await getDocs(q);
      setRelatedItems(querySnapshot.docs.map((doc) => doc.data()));
      console.log(location.pathname);
      console.log(querySnapshot.docs.map((doc) => doc.data()));
    };

    initializeEditor();

    fetchRelatedItems();

    return () => {
      isMounted = false;
      debouncedUpdate.cancel(); // Cancel any pending updates when component unmounts
    };
  }, [location.pathname, userId]);

  const handleSaveGoalDetails = async (updatedContent, originalContent) => {
    if (!docId) {
      console.error("No document ID available for update.");
      return;
    }
  
    try {
      const docRef = doc(db, "Desktop", docId);
  
      let updates = {};
  
      for (const key in updatedContent) {
        if (updatedContent[key] !== originalContent[key]) {
          updates[key] = updatedContent[key];
        }
      }
  
      await updateDoc(docRef, updates);
  
      alert("Goal updated successfully!");
    } catch (error) {
      console.error("Error updating goal:", error);
    }
  };
  
    

  const handleDocumentTypeChange = async (event) => {
    const newType = event.target.value;
    setDocumentType(newType);

    if (!docId) {
      console.error("No document ID available for type update.");
      return;
    }

    try {
      const docRef = doc(db, "Desktop", docId);
      await updateDoc(docRef, { type: newType });
    } catch (error) {
      console.error("Error updating document type:", error);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const changeDocumentPublicStatus = async () => {
    if (!docId) {
      console.error("No document ID available for updating public status.");
      return;
    }

    // Determine the new status
    const newStatus = documentData.public === "public" ? "private" : "public";

    // Update local state
    const updatedDocumentData = { ...documentData, public: newStatus };
    setDocumentData(updatedDocumentData);

    // Update Firestore document
    try {
      const docRef = doc(db, "Desktop", docId);
      await updateDoc(docRef, {
        public: newStatus,
      });
    } catch (error) {
      console.error("Error updating document's public status:", error);
    }
  };

  const changePublicStatus = async () => {
    changeDocumentPublicStatus(docId, documentData, updateDoc);
  };

  const closeGoalPopup = () => {
    setShowGoalPopup(false); // Assuming setShowGoalPopup is the setter for showGoalPopup state
  };


<GoalPopup
  show={showGoalPopup}
  onClose={closeGoalPopup}
  fileDetails={selectedGoalDetails}
  onSave={handleSaveGoalDetails} // Passing the function as a prop
/>


  const handleEditorChange = (state) => {
    setEditorState(state);
    const content = JSON.stringify(convertToRaw(state.getCurrentContent()));
    debouncedUpdate(content); // Call debounced update
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ paddingTop: "55px" }}>
        <div>
          <input
            type="text"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            placeholder="Filter by name"
          />
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="">All Types</option>
            <option value="Routine">Routine</option>
            <option value="txt">txt</option>
            <option value="Habbit">Habbit</option>
            <option value="Folder">Folder</option>
            <option value="Table">Table</option>
            <option value="Contact">Contact</option>
            <option value="Favorite">Favorite</option>
            <option value="note">note</option>
            <option value="File">File</option>
          </select>
        </div>
        <div
          style={{ display: "flex", overflowX: "auto", whiteSpace: "nowrap" }}
        >
          {filteredItems.map((item, index) => (
            <div
              key={item.id || index}
              style={{ margin: "10px", textAlign: "center" }}
            >
              <div>{getFileIcon(item.type)}</div>

              <div style={{ fontSize: "40px" }}>
                <div>{getFileIcon(item.type)}</div>
              </div>
              <div style={{ fontSize: "20px", color: "red", marginTop: "5px" }}>
                <button onClick={() => handleNavigate(item.path)}>
                  {item.fileName || "Unnamed File"}{" "}
                  {/* Fallback for empty or undefined names */}
                </button>
              </div>
            </div>
          ))}
        </div>
        {/* Only show button if the user is the document owner */}
        {isOwner && (
       <button onClick={toggleTypeFile}>
       {documentType || "Select File Type"}
     </button>

     
        )}
                {showTypeFile && (
          <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1000 }}>
            <TypeFile onFileTypeSelect={handleFileTypeSelect} />
          </div>
        )}
        {isOwner && (
          <div>
            <button onClick={onGoalClick}>Goal</button>
            <GoalPopup
              show={showGoalPopup}
              onClose={closeGoalPopup}
              fileDetails={selectedGoalDetails}
              onSave={handleSaveGoalDetails}
            />

            <button onClick={changePublicStatus}>
              {isPublicDocument ? "Make Private" : "Make Public"}
            </button>
          </div>
        )}
        <p>
          Last updated:{" "}
          {lastUpdated ? lastUpdated.toLocaleString() : "Not saved yet"}
        </p>
        {isOwner && documentType === "Table" && (
          <Spreadsheet
            data={spreadsheetData}
            onChange={setSpreadsheetData} // Update state when spreadsheet changes
          />
        )}
        {isOwner && (
          <button
            onClick={() =>
              playRoutine(
                setIsPlaying,
                setCurrentStepIndex,
                steps,
                (step, stepIndex) =>
                  startTimer(
                    step,
                    stepIndex,
                    setTimer,
                    intervalId,
                    setIntervalId,
                    setCurrentStepIndex,
                    steps,
                    finishRoutine
                  )
              )
            }
          >
            Play Routine
          </button>
        )}

        {isPlaying && (
          <div>
            {/* Display current step information */}
            <p>
              Step {steps[currentStepIndex].order}:{" "}
              {steps[currentStepIndex].name}
            </p>
            <p>Description: {steps[currentStepIndex].description}</p>
            <p>Link: {steps[currentStepIndex].link}</p>
            <p>Time remaining: {timer} seconds</p>

            {/* Control buttons */}
            <button onClick={pauseTimer}>Pause</button>
            <button onClick={stopRoutine}>Stop</button>
            <button onClick={skipStep}>Skip</button>
            <button onClick={finishRoutine}>Finish</button>
          </div>
        )}
        {showFinished && <p>Great, you finished!</p>}
        {isOwner && documentType === "Routine" && (
          <div>
            <div>
              <div>
                <input
                  type="text"
                  value={stepName}
                  onChange={(e) => setStepName(e.target.value)}
                  placeholder="Step Name"
                />
                <button onClick={addStep}>Add Step</button>
              </div>
              <label>
                Hours:
                <input
                  type="number"
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                  placeholder="Hours"
                />
              </label>
              <div>
                <label>
                  Minutes:
                  <input
                    type="number"
                    value={minutes}
                    onChange={(e) => setMinutes(e.target.value)}
                    placeholder="Minutes"
                  />
                </label>
              </div>
              <div>
                <label>
                  Seconds:
                  <input
                    type="number"
                    value={seconds}
                    onChange={(e) => setSeconds(e.target.value)}
                    placeholder="Seconds"
                  />
                </label>
              </div>
            </div>

            <textarea
              value={routineDescription}
              onChange={(e) => setRoutineDescription(e.target.value)}
              placeholder="Routine Description"
              style={{
                width: "100%",
                height: "auto",
                minHeight: "100px",
                resize: "vertical",
              }}
            />

            {/* Link URL input field */}
            <input
              type="text"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              placeholder="Link URL"
              style={{ width: "100%" }}
            />

            {/* Display added steps */}
            <div>
              {steps.map((step, index) => (
                <Step key={index} step={step} index={index} />
              ))}
            </div>
          </div>
        )}
        {/* Render editor or content based on ownership and public/private status */}
        {isOwner ? (
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            toolbar={toolbarOptions}
          />
        ) : isPublicDocument ? (
          <Editor
            editorState={editorState}
            readOnly={true}
            toolbarHidden={true}
          />
        ) : (
          <p>This document is private.</p>
        )}
      </div>
    </DndProvider>
  );
}

export default DeskFile;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import VerifiedBadge from '../Components/VerifiedBadge'; // Adjust the import path as needed


function UsersPages() {
    const { username, pagename } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [pageData, setPageData] = useState(null);
    const [newPageName, setNewPageName] = useState(pagename);
    const [isSaving, setIsSaving] = useState(false);
    const [documentExists, setDocumentExists] = useState(true);
    const navigate = useNavigate();
    const [ownerData, setOwnerData] = useState(null); // State to store owner's user data
    const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // Default white
    const [backgroundImage, setBackgroundImage] = useState('');


    const currentUser = auth.currentUser;
    const isOwner = currentUser?.displayName === username;
    const [linkData, setLinkData] = useState({
      link1: ['', '', '#ffffff'], // and so on for link2, link3, ..., link10
      link2: ['', '', '#ffffff'],
      link3: ['', '', '#ffffff'],
      link4: ['', '', '#ffffff'],
      link5: ['', '', '#ffffff'],
      link6: ['', '', '#ffffff'],
      link7: ['', '', '#ffffff'],
      link8: ['', '', '#ffffff'],
      link9: ['', '', '#ffffff'],
      link10: ['', '', '#ffffff']

    });
  
  // You can now use username and pagename to fetch data or perform other actions
  const verifiedBadgeStyle = (verifiedLevel) => ({
    position: 'absolute',
    bottom: '0',
    right: '0',
    backgroundColor: getBadgeColor(verifiedLevel),
    color: 'white',
    borderRadius: '50%',
    padding: '5px',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    zIndex: '2',
  });
  
  

  useEffect(() => {
    const fetchData = async () => {
        const path = `/link/u/${username}/${pagename}`;
        const q = query(collection(db, 'links'), where('path', '==', path));
        const pageSnapshot = await getDocs(q);

        if (!pageSnapshot.empty) {
            const pageDocument = pageSnapshot.docs[0];
            setPageData(pageDocument.data());
            setNewPageName(pageDocument.data().pageName);
            setDocumentExists(true);

            // Fetch owner's data using the username from page data
            const ownerUsername = pageDocument.data().username; // Ensure username is stored in the link document
            const userQuery = query(collection(db, 'users'), where('username', '==', ownerUsername));
            const userSnapshot = await getDocs(userQuery);

            if (!userSnapshot.empty) {
                const ownerDoc = userSnapshot.docs[0];
                setOwnerData(ownerDoc.data());
            }
            console.log('Owner Verified Status:', ownerData?.verified);

        } else {
            setDocumentExists(false);
        }
    };

    fetchData();
}, [username, pagename]);


const getBadgeColor = (verifiedLevel) => {
  const colors = {
    1: 'grey',
    2: 'black',
    3: 'yellow',
    4: 'green',
    5: 'darkgreen',
    6: 'blue',
    7: 'purple',
    8: 'cyan',
  };

  return colors[verifiedLevel] || 'transparent'; // Default to 'transparent' if level not found
};




useEffect(() => {
  if (pageData) {
    const newLinkData = {};
    for (let i = 1; i <= 10; i++) {
      newLinkData[`link${i}`] = pageData[`link${i}`] || ['', ''];
    }
    setLinkData(newLinkData);
    setBackgroundColor(pageData.backgroundColor || '#ffffff');
    setBackgroundImage(pageData.backgroundImage || '');
  }
}, [pageData]);


  const handleEdit = () => {
    setEditMode(true);
    
  };

  const profilePictureContainerStyle = {
    position: 'relative', // Relative position for the container
    display: 'inline-block',
    width: '100px', // Set the width to match the image
    height: '100px', // Set the height to match the image
  };
  
  const profilePictureStyle = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
    border: '3px solid white',
  };


  const renderBackgroundSettings = () => {
    if (!isOwner || !editMode) {
      return null; // Hide background settings if not the owner or not in edit mode
    }

    const backgroundStyle = backgroundImage
    ? { backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }
    : { backgroundColor };


    
    return (
      <div>
        <label>Background Color:</label>
        <input
          type="color"
          value={backgroundColor}
          onChange={(e) => setBackgroundColor(e.target.value)}
          title="Choose a background color"
        />
        <label>Background Image URL:</label>
        <input
          type="text"
          value={backgroundImage}
          onChange={(e) => setBackgroundImage(e.target.value)}
          placeholder="Background image URL"
        />
      </div>
    );
  };

  const renderLinks = () => {
    if (!pageData) {
      return <p>Loading links...</p>; // Or any other loading placeholder
    }
  
    let hasLinks = false;
    const linksComponents = [];
  
    for (let i = 1; i <= 10; i++) {
      const link = linkData[`link${i}`];
      if (link && link[0] && link[1]) {
        hasLinks = true;
        linksComponents.push(
          <a 
            key={i} 
            href={link[1]} 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', marginBottom: '10px' }}
          >
            <button style={{ width: '200px', backgroundColor: link[2] }}>
              {link[0]}
            </button>
          </a>
        );
      }
    }
  
    return hasLinks ? (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {linksComponents}
      </div>
    ) : <p>No links found.</p>;
  };
  

    // Function to render link edit fields
  const renderLinkEditFields = () => {
  const fields = [];
  for (let i = 1; i <= 10; i++) {
    fields.push(
      <div key={i}>
        <input 
          type="text" 
          value={linkData[`link${i}`][0]} 
          onChange={(e) => handleLinkChange(i, 0, e.target.value)} 
          placeholder={`Link ${i} Name`} 
        />
        <input 
          type="text" 
          value={linkData[`link${i}`][1]} 
          onChange={(e) => handleLinkChange(i, 1, e.target.value)} 
          placeholder={`Link ${i} URL`} 
        />
        <input
          type="color"
          value={linkData[`link${i}`][2]}
          onChange={(e) => handleLinkChange(i, 2, e.target.value)}
          title={`Choose background color for Link ${i}`}
        />
      </div>
    );
  }
  return fields;
};
  
    // Handle change in link edit fields
    const handleLinkChange = (linkNumber, index, value) => {
      setLinkData(prevData => ({
        ...prevData,
        [`link${linkNumber}`]: [
          ...prevData[`link${linkNumber}`].slice(0, index),
          value,
          ...prevData[`link${linkNumber}`].slice(index + 1)
        ]
      }));
    };
    
  



  console.log('Owner Data:', ownerData); // Log the owner data

  if (!documentExists) {
    return (
      <div>
        <p>We couldn't find this page.</p>
        <button onClick={() => navigate('/')}>Go Home</button>
      </div>
    );
  }

  const handleUsernameClick = () => {
    navigate(`/u/${username}`);
};
  
const handleSave = async () => {
  setIsSaving(true);

  const path = `/link/u${username}/${pagename}`;
  try {
    const q = query(collection(db, 'links'), where('path', '==', path));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const document = querySnapshot.docs[0]; // Assuming path is unique and only one document will match
      const pageRef = doc(db, 'links', document.id);

      const updatedData = {
        pageName: newPageName,
        ...linkData,
        backgroundColor,
        backgroundImage,
      };

      await updateDoc(pageRef, updatedData);
      alert('Page and links updated successfully!');
      setPageData(prevData => ({ ...prevData, ...updatedData }));
    } else {
      alert('No matching document found.');
    }
  } catch (error) {
    console.error('Error updating page and links: ', error);
    alert('Failed to update page and links.');
  } finally {
    setIsSaving(false);
    setEditMode(false);
  }
};

const ownerInfoStyle = {
  backgroundColor: 'rgba(2, 175, 255, 0.8)', // #02afff with 0.8 opacity
  borderRadius: '6px', // Rounded corners
  padding: '3px 5px', // Minimal padding for tight fitting
  margin: '3px', // Small margin
  textAlign: 'center', // Center the text
  color: 'white', // White text for contrast
  cursor: 'default', // Default cursor
  fontSize: '0.9em', // Adjusted font size
  fontWeight: 'bold', // Bold text for readability
  letterSpacing: '0.05em', // Slight letter spacing for clarity
};

const textStyle = {
  margin: '0', // Remove default margins
  padding: '0', // Ensure no padding is added
};



  return (
    <div>
      
      {ownerData && (
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        marginTop: '50px', 
        marginBottom: '10px',
        backgroundSize: 'cover', 
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none', 
        backgroundColor: backgroundColor 
      }}>
           <div style={profilePictureContainerStyle}>
              <img 
                src={ownerData.profilePicture} 
                alt={`${ownerData.firstName} ${ownerData.lastName}`} 
                style={profilePictureStyle} 
              />
              <VerifiedBadge verifiedLevel={ownerData.verified} />
            </div>
<div>
  <div style={ownerInfoStyle}>
  <h2 style={{ margin: '0', cursor: 'pointer' }} onClick={handleUsernameClick}>{ownerData.username}</h2>
  </div>
  <div style={ownerInfoStyle}>
  <h5 style={{ margin: '0', cursor: 'pointer' }} onClick={handleUsernameClick}>{ownerData.firstName} {ownerData.lastName}</h5>

    </div>
  {/* Display other owner details as needed */}
</div>
                </div>
            )}
      {!editMode && (
        <>
          <h1 style={{ textAlign: 'center' }}>{pageData ? pageData.pageName : 'Loading...'}</h1>

          <div style={{ textAlign: 'center' }}>
            {renderLinks()}
            {renderBackgroundSettings()}

          </div>
        </>
      )}
      {editMode && (
        <div>
          {/* Page name input, link edit fields, and background settings */}
          <input type="text" value={newPageName} onChange={(e) => setNewPageName(e.target.value)} disabled={isSaving} />
          {renderLinkEditFields()}
          {renderBackgroundSettings()}
          <button onClick={handleSave} disabled={isSaving}>Save</button>
          <div style={{height: '200px'}}>
            </div>
        </div>
      )}
    {isOwner && !editMode && <button style={{ borderRadius: '50%' }} onClick={handleEdit}>Edit</button>}
    {pageData && <div>{/* Render pageData content here */}</div>}
    
</div>
  );
}

export default UsersPages;

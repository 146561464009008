import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase"; // Adjust the import path as needed
import "../CSS/UsersProfiles.css"; // Adjust the import path as needed
import VerifiedBadge from '../Components/VerifiedBadge'; // Adjust the import path as needed


function UsersProfiles() {
  const { username } = useParams();
  const [userData, setUserData] = useState(null);
  const [editData, setEditData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [userDocId, setUserDocId] = useState(null);


  const profilePictureContainerStyle = {
    position: 'relative',
    display: 'inline-block', // ensures the container is only as big as its contents
  };


  useEffect(() => {
    const fetchUserData = async () => {
      const q = query(
        collection(db, "users"),
        where("username", "==", username)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0]; // Define docSnapshot here
        const data = docSnapshot.data();
        setUserData(data);
        setEditData(data); // Initialize edit data with user data
        setUserDocId(docSnapshot.id); // Store the document ID

        setIsOwner(auth.currentUser?.displayName === data.username);
      } else {
        setUserData(null);
      }
    };

    fetchUserData();
  }, [username]);

  const renderAdditionalInfo = () => {
    return (
      <div className="additional-info">

        {userData.customLink && <a href={userData.customLink} className="social-button" target="_blank" rel="noopener noreferrer">Custom Link</a>}
        <div className="bio-container">
          {isEditMode ? (
            <textarea
              name="bio"
              value={editData.bio}
              onChange={handleChange}
              className="editable-textarea"
            />
          ) : (
            <button className="bio-text">{userData.bio}</button>
          )}
        </div> 
      </div>
    );
  };

  const renderProfileTopButtons = () => (
    <div className="profile-top-buttons">
      {isOwner && (
        <button
          className="profile-button"
          onClick={isEditMode ? handleSave : handleEditProfile}
        >
          {isEditMode ? "Save" : "Edit Profile"}
        </button>
      )}
      {!isEditMode && (
        <>
          <button className="profile-button">Follow</button>
          <button className="profile-button">Message</button>
        </>
      )}
    </div>
  );


  const backgroundStyle = userData ? {
    backgroundImage: userData.backgroundImage ? `url(${userData.backgroundImage})` : '',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: userData.backgroundImage ? 'transparent' : '#02afff'
  } : {};

  const handleEditProfile = () => {
    setIsEditMode(!isEditMode);
  };

  const handleChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    // Check if we have a valid document ID
    if (userDocId) {
      const userDocRef = doc(db, "users", userDocId);
      await updateDoc(userDocRef, editData);
      setIsEditMode(false);
      setUserData(editData);
    } else {
      console.error("No document ID found for update");
    }
  };

  const handleImageError = (e) => {
    e.target.src = "default-profile.jpg"; // Path to your default image
    console.error("Error loading profile image");
  };

  const renderSocialLink = (platform, url) => {
    if (!url) return null;
    return (
      <a
        href={url}
        className="social-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {platform}
      </a>
    );
  };

  const renderProfileField = (label, value, fieldName, isEditable) => {
    return (
      <div className="profile-field">
        <strong>{label}:</strong>
        {isEditable ? (
          <input
            type="text"
            name={fieldName}
            value={value}
            onChange={handleChange}
          />
        ) : (
          <span>{value}</span>
        )}
      </div>
    );
  };

  if (!userData) {
    return (
      <div className="users-profile-container">
        We didn't find that username
      </div>
    );
  }

  return (
    <div className="users-profile-container" style={backgroundStyle}>
              {renderProfileTopButtons()}


              <div style={profilePictureContainerStyle}>

      {isEditMode ? (
        <div className="profile-field">
          <strong>Profile Picture:</strong>
          <input
            type="text"
            name="profilePicture"
            value={editData.profilePicture}
            onChange={handleChange}
          />
        </div>
      ) : (
        <>
        <img
          src={userData.profilePicture || "default-profile.jpg"}
          alt={`${userData.firstName} ${userData.lastName}`}
          className="profile-picture"
          onError={handleImageError}
        />
                    <VerifiedBadge verifiedLevel={userData.verified} />

        </>
      )}
        </div>  
      <button className="profile-button username-button">
        {userData.username}
      </button>{" "}

      <button className="profile-button">{userData.firstName} {userData.lastName}</button> {/* Add this button */}

      {!isEditMode && renderAdditionalInfo()}
      <div className="social-links">
        <button className="social-button">Email</button>
        <button className="social-button">Phone</button>
        <button className="social-button">Facebook</button>
        <button className="social-button">Instagram</button>
      </div>
      {isEditMode ? (
        <>
                <div className="profile-field">
          <strong>Background Image URL:</strong>
          <input
            type="text"
            name="backgroundImage"
            value={editData.backgroundImage || ''}
            onChange={handleChange}
          />
        </div>
          {renderProfileField(
            "Custom Link",
            editData.customLink,
            "customLink",
            true
          )}
                    {renderProfileField("Bio", editData.bio, "bio", true)}

          {/* Render editable fields for each profile detail */}
          {renderProfileField("Email", editData.realEmail, "realEmail", true)}
          {renderProfileField(
            "Phone",
            editData.phoneNumber,
            "phoneNumber",
            true
          )}
          {renderProfileField(
            "Facebook",
            editData.facebookLink,
            "facebookLink",
            true
          )}
          {renderProfileField(
            "Instagram",
            editData.instagramLink,
            "instagramLink",
            true
          )}
        </>
      ) : (
        <></>
      )}
      {/* ...other fields... */}
    </div>
  );
}

export default UsersProfiles;

import React, { useState } from "react";
import { useEffect } from "react";
import "./Home.css"; // Ensure you have the corresponding CSS file
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  setDoc,
} from "firebase/firestore";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { SecularOneRegularBase64 } from "./fontSecularReg"; // Adjust the path as necessary

pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;
pdfMake.vfs["DejaVuSans-normal.ttf"] = SecularOneRegularBase64;

pdfMake.fonts = {
  DejaVuSans: {
    normal: "DejaVuSans-normal.ttf",
    bold: "DejaVuSans-normal.ttf",
    italics: "DejaVuSans-normal.ttf",
    bolditalics: "DejaVuSans-normal.ttf",
  },
  // ... other fonts
};

function Home() {
  const [itemQuantities, setItemQuantities] = useState([]);
  const [items, setItems] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showGeneralPopup, setShowGeneralPopup] = useState(false);
  const [generalQuantity, setGeneralQuantity] = useState(0);
  const categoryOrder = { Flowers: 1, Vegetables: 2, Others: 3 };
  const [filterQuery, setFilterQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [tempPriceChanges, setTempPriceChanges] = useState({});
  const [showItemsWithQuantity, setShowItemsWithQuantity] = useState(false);
  const [total, setTotal] = useState(0);

  const [editingItem, setEditingItem] = useState(null);
  const [editFormData, setEditFormData] = useState({
    itemName: "",
    unitType: "",
    itemImageUrl: "",
    itemPriceDollars: "",
    itemPriceCents: "",
    quantity: 0,
  });

  const [customerName, setCustomerName] = useState("");

  const generatePDF = () => {
    pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;
    pdfMake.vfs["SecularOne-Regular.ttf"] = SecularOneRegularBase64;

    // Filter items with quantity greater than 0 and calculate total price for each item
    const filteredItems = items
      .filter((item) => item.quantity > 0)
      .map((item) => {
        const totalPrice = parseFloat(item.price) * item.quantity;
        return [
          `${totalPrice.toFixed(2)}₪`,
          item.quantity.toString(),
          `${item.price}₪`,
          item.itemName,
        ];
      });

    const now = new Date();
    const formattedDate = now.toLocaleDateString("he-IL");
    const formattedTime = now.toLocaleTimeString("he-IL");
    // Calculate the total of all items combined
    const totalOfAllItems = filteredItems.reduce((sum, currentItem) => {
      const totalPrice = parseFloat(currentItem[0].replace("₪", "")); // Extract and convert the total price to a number
      return sum + totalPrice;
    }, 0);
    const tableBody = [
      ['סה"כ', "כמות", "מחיר", "מוצר"].map((text) => ({
        text,
        alignment: "right",
      })), // Header row
      ...filteredItems.map((row) =>
        row.map((cell) => ({ text: cell, alignment: "right" }))
      ), // Data rows
      [totalOfAllItems.toFixed(2) + "₪", "", "", "" + 'סה"כ:'].map((text) => ({
        text,
        alignment: "right",
      })), // Total row
    ];

    const docDefinition = {
      content: [
        {
          text: ` ${formattedDate}     ${formattedTime}`,
          style: "date",
        },
        {
          text: " הזמנה תופס ",

          style: "header",
        },
        customerName && {
          text: `  ${customerName} לקוח:`,
          style: "subheader",
          alignment: "right",
        },
        {
          table: {
            // Headers and data rows
            body: tableBody,
            widths: ["*", "*", "*", "*"], // Adjust column widths as needed
          },
          layout: "lightHorizontalLines", // Optional: Adds layout to the table
          rtl: true, // Right-to-Left text
        },
        {
          text: "חתימה:_______________________",
          style: "subheader",
          alignment: "right",
        },
      ],
      defaultStyle: {
        font: "DejaVuSans",
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: "right", // Right align the header
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 5, 0, 5], // Adjust as needed
          // Other styles...
        },
        paragraph: {
          fontSize: 12,
          margin: [0, 5, 0, 5], // Adjust as needed
          // Other styles...
        },
        date: {
          fontSize: 9,
          margin: [0, 5, 0, 5], // Adjust as needed
          // Other styles...
        },

        // Other styles as needed
      },
    };

    try {
      pdfMake.createPdf(docDefinition).download("items.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      alert("Failed to generate PDF. Please try again later.");
    }
  };

  const handleEditClick = (item) => {
    setEditingItem(item);
    setEditFormData({
      itemName: item.itemName,
      unitType: item.unitType,
      itemImageUrl: item.itemImageUrl,
      itemPriceDollars: item.price.split(".")[0],
      itemPriceCents: item.price.split(".")[1],
      quantity: item.quantity,
    });
  
    // Update the unitType state to reflect the editing item's unitType
    setUnitType(item.unitType);
  
    setItemName(item.itemName);
    setShowPopup(true);
  };
  

  const handleEditSubmit = async () => {
    const updatedItem = {
      ...editingItem,
      itemName: editFormData.itemName,
      unitType: editFormData.unitType,
      itemImageUrl: editFormData.itemImageUrl,
      price: `${editFormData.itemPriceDollars}.${editFormData.itemPriceCents}`,
      quantity: editFormData.quantity
    };
  
    try {
      const itemRef = doc(db, "wwwebs", "MeshekDaniel", "Products", editingItem.id);
      await setDoc(itemRef, updatedItem);
      alert("Product updated successfully!");
  
      setItems(prevItems =>
        prevItems.map(item => (item.id === editingItem.id ? updatedItem : item))
      );
      setShowPopup(false);
    } catch (error) {
      console.error("Error updating product: ", error);
      alert("Failed to update product. Please try again later.");
    }
  
    setEditingItem(null);
    resetForm();
  };
  
  const handleCategorySelection = (category) => {
    setSelectedCategories((prevCategories) => {
      if (prevCategories.includes(category)) {
        return prevCategories.filter((cat) => cat !== category);
      } else {
        return [...prevCategories, category];
      }
    });
  };
  const toggleShowItemsWithQuantity = () => {
    setShowItemsWithQuantity(!showItemsWithQuantity);
  };

  const isCategorySelected = (category) => {
    return selectedCategories.includes(category);
  };
  const [itemName, setItemName] = useState("");
  const [unitType, setUnitType] = useState("Count");
  const [itemImageUrl, setItemImageUrl] = useState("");
  const [itemPriceDollars, setItemPriceDollars] = useState("");
  const [itemPriceCents, setItemPriceCents] = useState("");

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleGeneralPopup = () => {
    setShowGeneralPopup(!showGeneralPopup);
  };

  const sortedItems = [...items].sort((a, b) => {
    if (categoryOrder[a.unitType] === categoryOrder[b.unitType]) {
      return a.itemName.localeCompare(b.itemName); // Sort by name if same category
    }
    return categoryOrder[a.unitType] - categoryOrder[b.unitType]; // Sort by category
  });

  const resetForm = () => {
    setEditingItem(null);
    setEditFormData({
      itemName: "",
      unitType: "",
      itemImageUrl: "",
      itemPriceDollars: "",
      itemPriceCents: "",
      quantity: 0,
    });
    setItemName(""); // Add this line
    setUnitType("Count");
    setItemImageUrl("");
    setItemPriceDollars("");
    setItemPriceCents("");
  };

  const filteredAndSortedItems = items
    .filter((item) => {
      const itemNameLower = item.itemName.toLowerCase();
      const unitTypeLower = item.unitType.toLowerCase();
      const filterQueryLower = filterQuery.toLowerCase();
      const categoryFilter =
        selectedCategories.length === 0 ||
        selectedCategories.includes(item.unitType);

      return (
        (itemNameLower.includes(filterQueryLower) ||
          unitTypeLower.includes(filterQueryLower)) &&
        categoryFilter &&
        (!showItemsWithQuantity || item.quantity > 0)
      );
    })
    .sort((a, b) => {
      if (categoryOrder[a.unitType] === categoryOrder[b.unitType]) {
        return a.itemName.localeCompare(b.itemName);
      }
      return categoryOrder[a.unitType] - categoryOrder[b.unitType];
    });
  const handleGeneralSubmit = async () => {
    const newItem = {
      itemName,
      unitType,
      itemImageUrl: "",
      price: `${itemPriceDollars}.${itemPriceCents}`,
      quantity: generalQuantity,
    };

    setItems((prevItems) => [...prevItems, { id: itemName, ...newItem }]);
    setItemQuantities((prevQuantities) => [...prevQuantities, generalQuantity]);

    setItemName("");
    setUnitType("Count");
    setItemPriceDollars("");
    setItemPriceCents("");
    setGeneralQuantity();

    toggleGeneralPopup();
  };

  const db = getFirestore();

  const fetchItems = async () => {
    const itemsCollection = collection(
      db,
      "wwwebs",
      "MeshekDaniel",
      "Products"
    );
    const itemsSnapshot = await getDocs(itemsCollection);
    const itemsList = itemsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      quantity: 0, // Initialize quantity for each item
    }));

    setItems(itemsList);
    setItemQuantities(itemsList.map(() => 0)); // Initialize quantities array
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    setTotal(calculateTotal());
  }, [items, tempPriceChanges, itemQuantities]);

  const handleManualQuantityChange = (itemId, value) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId
          ? { ...item, quantity: Math.max(0, parseInt(value, 10) || 0) }
          : item
      )
    );
  };

  const handleAddItem = async () => {
    if (!itemName) {
      alert("Please enter a product name.");
      return;
    }
  
    const productData = {
      itemName,
      unitType,
      itemImageUrl, // Use the itemImageUrl state here
      price: `${itemPriceDollars}.${itemPriceCents}`,
      quantity: generalQuantity
    };
  
    try {
      const productRef = doc(db, "wwwebs", "MeshekDaniel", "Products", itemName);
      await setDoc(productRef, productData);
      alert("Product added successfully!");
  
      setItems(prevItems => [...prevItems, { id: itemName, ...productData }]);
      resetForm();
    } catch (error) {
      console.error("Error adding product: ", error);
      alert("Failed to add product. Please try again later.");
    }
  };
  

  const handleUnitTypeChange = (value) => {
    setUnitType(value);
  };

  const handleNumberInputChange = (value, setterFunction) => {
    if (/^\d*$/.test(value)) {
      setterFunction(value);
    }
  };

  const handleQuantityChange = (itemId, delta) => {
    setItems((currentItems) =>
      currentItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, quantity: Math.max(0, item.quantity + delta) };
        }
        return item;
      })
    );
  };

  const calculateTotal = () => {
    return items.reduce((acc, item) => {
      const price =
        tempPriceChanges[item.id] !== undefined
          ? parseFloat(tempPriceChanges[item.id]) || 0
          : parseFloat(item.price) || 0;
      return acc + price * (item.quantity || 0);
    }, 0);
  };

  return (
    <div className="home-container">
      <div className="top-buttons">
        <button
          onClick={toggleShowItemsWithQuantity}
          style={{ backgroundColor: showItemsWithQuantity ? "green" : "" }}
        >
          {showItemsWithQuantity ? "Showing List" : "הזמנה נוכחית"}
        </button>
        <button onClick={togglePopup}>מוצר חדש</button>
        <button onClick={toggleGeneralPopup}>מוצר כללי</button>
        <button
          onClick={() => handleCategorySelection("Flowers")}
          style={{
            backgroundColor: isCategorySelected("Flowers")
              ? "rgba(255, 0, 0, 0.5)"
              : "rgb(255, 101, 181)",
          }}
        >
          פרחים
        </button>
        <button
          onClick={() => handleCategorySelection("Vegetables")}
          style={{
            backgroundColor: isCategorySelected("Vegetables")
              ? "rgba(0, 128, 0, 0.5)"
              : "rgb(0, 128, 0)",
          }}
        >
          ירקות
        </button>
        <button
          onClick={() => handleCategorySelection("Others")}
          style={{
            backgroundColor: isCategorySelected("Others")
              ? "rgba(0, 0, 255, 0.5)"
              : "rgb(47, 224, 255)",
          }}
        >
          שונות
        </button>
      </div>
      <div className="customer-name-input">
        <input
          type="text"
          placeholder="שם הלקוח"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>{editingItem ? "Edit Product" : "Add New Product"}</h3>
            <div>
<div>
  <label htmlFor="flowers">Flowers</label>
  <input
  type="radio"
  id="flowers"
  name="unitType"
  value="Flowers"
  checked={unitType === "Flowers"}
  onChange={() => setUnitType("Flowers")}
/>
  <label htmlFor="vegetables">Vegetables</label>
  <input
    type="radio"
    id="vegetables"
    name="unitType"
    value="Vegetables"
    checked={unitType === "Vegetables"}
    onChange={() => setUnitType("Vegetables")}
  />
  <label htmlFor="others">Others</label>
  <input
    type="radio"
    id="others"
    name="unitType"
    value="Others"
    checked={unitType === "Others"}
    onChange={() => setUnitType("Others")}
  />
</div>
            </div>
            <input
              type="text"
              placeholder="שם מוצר"
              value={itemName} // Change this line
              onChange={(e) => setItemName(e.target.value)} // And this line
            />
            <label>:מוצר </label>
            <div className="popup-item-image">
            <input
        type="text"
        placeholder="Image URL"
        value={itemImageUrl}
        onChange={(e) => setItemImageUrl(e.target.value)}
      />
              <label>:תמונה</label>
            </div>
            <div>
            <input
        type="text"
        placeholder="Price in Dollars"
        value={itemPriceDollars}
        onChange={(e) => setItemPriceDollars(e.target.value)}
      />
      <input
        type="text"
        placeholder="Price in Cents"
        value={itemPriceCents}
        onChange={(e) => setItemPriceCents(e.target.value)}
      />
              <label>Price: </label>
            </div>
            <button onClick={editingItem ? handleEditSubmit : handleAddItem}>
              {editingItem ? "Update" : "Add"}
            </button>
            <button onClick={togglePopup}>סגירה</button>
          </div>
        </div>
      )}
      {showGeneralPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>הוספת מוצר כללי</h3>
            <div>
              <div>
                <label htmlFor="flowers">Flowers</label>
                <input
                  type="radio"
                  id="flowers"
                  name="unitType"
                  value="Flowers"
                  checked={unitType === "Flowers"}
                  onChange={() => handleUnitTypeChange("Flowers")}
                />
                <label htmlFor="vegetables">Vegetables</label>
                <input
                  type="radio"
                  id="vegetables"
                  name="unitType"
                  value="Vegetables"
                  checked={unitType === "Vegetables"}
                  onChange={() => handleUnitTypeChange("Vegetables")}
                />
                <label htmlFor="others">Others</label>
                <input
                  type="radio"
                  id="others"
                  name="unitType"
                  value="Others"
                  checked={unitType === "Others"}
                  onChange={() => handleUnitTypeChange("Others")}
                />
              </div>
            </div>
            <input
              type="text"
              placeholder="שם מוצר"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
            <label>:מוצר </label>
            <div className="popup-item-image">
              <input
                type="number"
                placeholder="כמות"
                value={generalQuantity}
                onChange={(e) => setGeneralQuantity(Number(e.target.value))}
              />
              <label>כמות: </label>
            </div>
            <div>
              <input
                type="text"
                placeholder="שקלים"
                value={itemPriceDollars}
                onChange={(e) =>
                  handleNumberInputChange(e.target.value, setItemPriceDollars)
                }
              />
              <a> </a>
              <label>:מחיר </label>
              <input
                type="text"
                placeholder="אגורות"
                value={itemPriceCents}
                onChange={(e) =>
                  handleNumberInputChange(e.target.value, setItemPriceCents)
                }
              />
              <label>Price: </label>
            </div>
            <button onClick={handleGeneralSubmit}>Submit</button>
            <button onClick={toggleGeneralPopup}>Close</button>
          </div>
        </div>
      )}
      {/* Items display */}
      <div className="items-container">
        {filteredAndSortedItems.map((item) => (
          <div
            key={item.id}
            className={`item ${item.unitType.toLowerCase()}`}
            style={{ backgroundImage: `url(${item.itemImageUrl})` }}
          >
            <div className="item-info">
              <h3>{item.itemName}</h3>
              {tempPriceChanges[item.id] !== undefined ? (
                <div>
                  <input
                    type="number"
                    value={tempPriceChanges[item.id]}
                    onChange={(e) =>
                      setTempPriceChanges({
                        ...tempPriceChanges,
                        [item.id]: e.target.value,
                      })
                    }
                  />
                  <button
                    onClick={() => {
                      // Update the item's price temporarily
                      setItems((prevItems) =>
                        prevItems.map((it) =>
                          it.id === item.id
                            ? { ...it, price: tempPriceChanges[item.id] }
                            : it
                        )
                      );
                      setTempPriceChanges({
                        ...tempPriceChanges,
                        [item.id]: undefined,
                      });
                    }}
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <h3
                  onClick={() =>
                    setTempPriceChanges({
                      ...tempPriceChanges,
                      [item.id]: item.price,
                    })
                  }
                >
                  {item.price}₪
                </h3>
              )}
            </div>
            <div className="item-quantity">
              <div className="item-quantity">
                <button onClick={() => handleQuantityChange(item.id, -1)}>
                  -
                </button>
                <input
                  type="number"
                  value={item.quantity}
                  onChange={(e) =>
                    handleManualQuantityChange(item.id, e.target.value)
                  }
                  min="0"
                />
                <button onClick={() => handleQuantityChange(item.id, 1)}>
                  +
                </button>
              </div>

              <div className="item-total">
                <label>
                  סה"כ:{" "}
                  {(
                    (tempPriceChanges[item.id] !== undefined
                      ? tempPriceChanges[item.id]
                      : item.price) * item.quantity
                  ).toFixed(2)}
                  ₪
                </label>
              </div>
              <button
                className="small-edit-btn"
                onClick={() => handleEditClick(item)}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="bottom-bar">
        <button onClick={generatePDF}>PDF</button>
        <a>Total: {isNaN(total) ? 0 : total.toFixed(2)}₪</a>
        <div>
          <input
            type="text"
            placeholder="שם מוצר/ קטגוריה / צבע"
            value={filterQuery}
            onChange={(e) => setFilterQuery(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;

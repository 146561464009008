import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { auth } from '../../firebase';

const db = getFirestore();

function Desktop() {
    const [files, setFiles] = useState([]);
    const navigate = useNavigate();
    const username = auth.currentUser?.displayName || 'unknown_user';
    const userId = auth.currentUser?.uid;

    useEffect(() => {
        const fetchFiles = async () => {
            const userId = auth.currentUser?.uid;
            console.log('Current User ID:', userId); // Debugging line
    
            if (!userId) {
                console.log('No user logged in.'); // Debugging line
                return;
            }
    
            const q = query(
                collection(db, 'Desktop'),
                where('userId', '==', userId),
                where('currentPath', '==', '/desktop') // Add this line
            );
            const querySnapshot = await getDocs(q);
            console.log('Fetched Files:', querySnapshot.docs.map(doc => doc.data())); // Debugging line
    
            // Define initial items
            const initialItems = [
                { fileName: 'Tasks', id: 'tasks-id',  path: '/Tasks'  /* other properties */ },
                { fileName: 'MessFolder', id: 'messfolder-id', path: '/desktop/u/'+username+'/MessFolder', userId: userId, content: '' /* other properties */ }
            ];
    
            // Prepend initial items to the fetched files and update state
            setFiles([...initialItems, ...querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))]);
        };
    
        fetchFiles();
    }, []);
    
    const moveFile = useCallback((dragIndex, hoverIndex) => {
        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            const dragFile = newFiles[dragIndex];
            newFiles.splice(dragIndex, 1);
            newFiles.splice(hoverIndex, 0, dragFile);
            return newFiles;
        });
    }, [setFiles]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                <h1>Hello, you are in desktop</h1>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {files.map((file, index) => (
    <FileCard key={file.id} file={file} index={index} moveFile={moveFile} navigate={navigate} />
))}
                </div>
            </div>
        </DndProvider>
    );
}

const FileCard = ({ file, index, moveFile, navigate }) => {
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
        type: 'file',
        item: () => {
            return { id: file.id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const handleClick = () => {
        // Navigate to the file's path
        navigate(file.path);
    };
    const [{ handlerId }, drop] = useDrop({
        accept: 'file',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
   hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get horizontal and vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;

            // Only perform the move when the mouse has crossed half of the item's width or height
            const shouldMove = (
                dragIndex < hoverIndex && hoverClientX > hoverMiddleX ||
                dragIndex > hoverIndex && hoverClientX < hoverMiddleX ||
                dragIndex < hoverIndex && hoverClientY > hoverMiddleY ||
                dragIndex > hoverIndex && hoverClientY < hoverMiddleY
            );

            if (shouldMove) {
                moveFile(dragIndex, hoverIndex);
                item.index = hoverIndex;
            }
        },
    });

    const cardStyle = {
        border: '1px solid gray',
        padding: '10px',
        margin: '10px',
        backgroundColor: 'white',
        color: 'black', // Ensure the text color is visible
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
    };

    const iconStyle = {
        width: '80px',
        height: '80px',
        backgroundColor: 'lightgray',
        marginBottom: '5px',
    };

        const style = {
        border: '1px dashed gray',
        padding: '0.5rem 1rem',
        marginBottom: '.5rem',
        backgroundColor: 'white',
        cursor: 'move',
    };


    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        
        <div ref={ref} style={cardStyle} data-handler-id={handlerId} onClick={handleClick}>
            <div style={iconStyle}>
                {/* Optional: Place for folder icon */}
            </div>
            <p>{file.fileName}</p> {/* Display the file's name */}
        </div>
    );
};

export default Desktop;
